<template>
  <div>
    <b-row class="mt-2 mb-3">
      <b-col
          lg="12"
      >
        <h2 style="text-align: center">{{ title }}</h2>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-3">
      <b-col
          lg="6"
      >
        <b-form @submit.prevent="updateInformation()">
          <b-row>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Antecedentes Importantes"
                  label-for="vi-first-name"
              >
                <b-form-textarea
                    id="textarea-default"
                    placeholder="Antecedentes Importantes"
                    v-model="general_information.important_background"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Alergias"
                  label-for="vi-first-name"
              >
                <b-form-textarea
                    placeholder="Alergias"
                    v-model="general_information.allergies"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Quién Refiere al paciente"
                  label-for="vi-first-name"
              >
                <b-form-textarea
                    placeholder="Quién Refiere al paciente"
                    v-model="general_information.refers_patient"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Motivo de la consulta (Según el paciente)"
                  label-for="vi-first-name"
              >
                <b-form-textarea
                    v-model="general_information.reason"
                    placeholder=""
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col
                lg="12"
            >
              <b-form-group
                  label="Observaciones sobre el paciente"
                  label-for="vi-first-name"
              >
                <b-form-textarea
                    placeholder=""
                    v-model="general_information.observations"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" style="margin-top: 10px">
              <div align="right">
                <b-button
                    style="width: 200px"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Guardar datos
                </b-button>
              </div>


            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col
          lg="6"
      >
        <h3 style="text-align: center">Historia Clinica</h3>
        <app-collapse>
          <app-collapse-item active title="Antecedentes Familiares" is-visible >
            <b-row>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Diabetes Mellitus:
                </h4>
                <b-form-radio
                    v-model="history.diabetes_mellitus"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.diabetes_mellitus"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group v-if="history.diabetes_mellitus"
                              label="Diabetes Mellitus"
                              label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.diabetes_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cancer:</h4>
                <b-form-radio
                    v-model="history.cancer"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.cancer"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.cancer"
                    label="Cancer"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.cancer_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hipertensión Arterial:
                </h4>
                <b-form-radio
                    v-model="history.arterial_hypertension"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.arterial_hypertension"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.arterial_hypertension"
                    label="Hipertensión Arterial"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.arterial_hypertension_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="12" class="mt-2"
              >
                <h4>Otros antecedentes:
                </h4>
                <b-form-textarea
                    placeholder=""
                    v-model="history.other_diseases"
                    rows="2"
                />

              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item active title="Antecedentes Personales Patológicos" >
            <b-row>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Diabetes Mellitus:
                </h4>
                <b-form-radio
                    v-model="history.diatebes_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.diatebes_personal"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <b-form-group
                    v-if="history.diatebes_personal"
                    label="Diabetes Mellitus"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.diabetes_personal_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cancer:</h4>
                <b-form-radio
                    v-model="history.cancer_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.cancer_personal"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <b-form-group
                    v-if="history.cancer_personal"
                    label="Cancer"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.cancer_personal_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Cirugías:
                </h4>
                <b-form-radio
                    v-model="history.surgeries"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.surgeries"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <b-form-group
                    v-if="history.surgeries"
                    label="Cirugías"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.surgeries_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hepatitis:
                </h4>
                <b-form-radio
                    v-model="history.hepatitis"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.hepatitis"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4 v-if="history.hepatitis">Tipo de hepatitis:
                </h4>
                <b-form-select
                    v-if="history.hepatitis"
                    v-model="history.hepatitis_type"
                    :options="options3"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
              <b-col
                  lg="5"
              >
                <b-form-group
                    v-if="history.hepatitis"
                    label="Hepatitis"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.hepatitis_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Hipertensión Arterial:
                </h4>
                <b-form-radio
                    v-model="history.arterial_personal"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.arterial_personal"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <b-form-group
                    v-if="history.arterial_personal"
                    label="Hipertensión Arterial"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.arterial_personal_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Ingesta Actual de Medicamentos:
                </h4>
                <b-form-radio
                    v-model="history.medicines"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.medicines"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
              >
                <b-form-group
                    v-if="history.medicines"
                    label="Ingesta Actual de Medicamentos"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.medicines_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Grupo sanguineo:
                </h4>
                <b-form-select
                    v-model="history.blood_group"
                    :options="options"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Factor RH:
                </h4>
                <b-form-select
                    v-model="history.rh"
                    :options="options2"
                    size="sm"
                    class="mt-1"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Antecedentes Personales NO Patológicos">
            <b-row>
              <b-col
                  lg="4" class="mt-2"
              >
                <h4>Toma Bebidas Alcohólicas:
                </h4>
                <b-form-radio
                    v-model="history.alcoholism"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.alcoholism"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="4"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.alcoholism"
                    label="Cuanto toma por dia"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.alcoholism_day"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="4"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.alcoholism"
                    label="Cuanto toma por semana"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.alcoholism_week"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Fuma:</h4>
                <b-form-radio
                    v-model="history.smoking"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.smoking"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.smoking"
                    label="Veces que fuma"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.smoking_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Drogas:
                </h4>
                <b-form-radio
                    v-model="history.drug_intake"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.drug_intake"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.drug_intake"
                    label="Veces que consume drogas"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.drug_intake_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3" class="mt-2"
              >
                <h4>Ejercicio:
                </h4>
                <b-form-radio
                    v-model="history.exercise"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.exercise"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="9"
                  class="mt-2"
              >
                <b-form-group
                    v-if="history.exercise"
                    label="Frecuencia de ejercicio"
                    label-for="vi-first-name"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.exercise_observations"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Antecedentes Ginecológicos">
            <b-row>
              <b-col
                  lg="12"
              >
                <b-form-group
                    label="Comentarios"
                >
                  <b-form-textarea
                      placeholder=""
                      v-model="history.comments"
                      rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="3"
                  class="mt-2"
              >
                <b-form-group
                    label="Menarca"
                    label-for="vi-first-name"
                >
                  <b-form-input
                      placeholder=""
                      v-model="history.menarche"
                      rows="2"
                  />
                </b-form-group>
                <h4>Gestaciones:
                </h4>
                <b-form-radio
                    v-model="history.gestations"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.gestations"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.gestations">
                <b-col
                    lg="1"
                >
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Partos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.childbirth"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Cesáreas"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.caesarean"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Abortos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.abortions"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Óbitos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.deaths"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                  <b-form-group
                      label="Ectópicos"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.ectopic"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="5">
                  <b-form-group
                      label="Fecha de ultima gestacion"
                  >
                    <flat-pickr
                        v-model="history.date_gestations"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">

                </b-col>
                <b-col cols="3">

                </b-col>
                <b-col cols="9">
                  <b-form-group
                      label="Comentarios"
                  >
                    <b-form-textarea
                        placeholder=""
                        v-model="history.comments_menarche"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12">
                <h4>Continúa Menstruando:
                </h4>
                <b-form-radio
                    v-model="history.continue_menstruating"
                    :value="true"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                    v-model="history.continue_menstruating"
                    :value="false"
                >
                  No
                </b-form-radio>
              </b-col>
              <b-col
                  lg="12"
                  class="mt-2"
              >
              </b-col>
              <b-row v-if="history.continue_menstruating">
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col
                    lg="4"
                >
                  <b-form-group
                      label="Duración (Días)"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.duration_menstruating"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="4"
                >
                  <b-form-group
                      label="Frecuencia(Días)"
                      label-for="vi-first-name"
                  >
                    <b-form-input
                        placeholder=""
                        v-model="history.frequency_menstruating"
                        rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col cols="1">

                </b-col>
                <b-col cols="11">
                  <b-form-checkbox
                      v-model="history.irregular_menstrual"
                      :value="history.irregular_menstrual ? true : ''"
                  >
                    Ciclos mentruales irregulares
                  </b-form-checkbox>
                  <b-form-checkbox
                      v-model="history.contraceptives"
                      :value="history.contraceptives ? true : ''"
                  >
                    Anticonceptivos
                  </b-form-checkbox>
                </b-col>
                <b-col cols="3" class="mt-3">

                </b-col>
                <b-col cols="5" class="mt-3">
                  <b-form-group
                      label="Fecha de ultima Regla"
                  >
                    <flat-pickr
                        v-model="history.date_rule"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row v-else>
                <b-col
                    lg="2"
                >
                </b-col>
                <b-col cols="10" >
                  <b-form-group
                      label="Desde cuando"
                  >
                    <flat-pickr
                        v-model="history.date_rule_no"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-row>
          </app-collapse-item>
        </app-collapse>


        <b-col cols="12" style="margin-top: 10px">
          <div align="right">

            <b-button
                @click="updateHistory()"
                style="width: 200px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
            >
              Guardar historial
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BFormFile, BForm, BFormSelect, BFormRadio,BFormCheckbox
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import moment from 'moment';

export default {
  name: "InformationPatient",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile, flatPickr, BFormSelect, BFormRadio, ToastificationContent, AppCollapse,
    AppCollapseItem,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    title: String,
    data_patient: Object
  },

  data() {
    return {
      create_history: false,
      options: [
        {value: "", text: 'Selecciona el grupo sanguineo'},
        {value: 'Tipo A', text: 'Tipo A'},
        {value: 'Tipo B', text: 'Tipo B'},
        {value: 'Tipo AB', text: 'Tipo AB'},
        {value: 'Tipo O', text: 'Tipo O'},
      ],
      options2: [
        {value: "", text: 'Selecciona el factor rh'},
        {value: 'Rh+', text: 'Rh+'},
        {value: 'Rh-', text: 'Rh-'},
      ],
      options3: [
        {value: "", text: 'Selecciona el tipo de hepatitis'},
        {value: 'Hepatitis A', text: 'Hepatitis A'},
        {value: 'Hepatitis B', text: 'Hepatitis B'},
        {value: 'Hepatitis C', text: 'Hepatitis C'},
        {value: 'Hepatitis D', text: 'Hepatitis D'},
        {value: 'Hepatitis E', text: 'Hepatitis E'},
      ],
      general_information: {
        id: '',
        important_background: null,
        allergies: null,
        refers_patient: null,
        reason: null,
        observations: null,
        patient_id: null,
      },
      history: {
        id: null,
        diabetes_mellitus: false,
        diabetes_observations: "",
        cancer: false,
        cancer_observations: "",
        date_rule_no:  moment().format('YYYY-MM-DD'),
        cancer_personal: false,
        cancer_personal_observations: "",
        arterial_hypertension: false,
        arterial_hypertension_observations: "",
        pathological_observations: "",
        drug_intake: false,
        drug_intake_observations: "",
        surgeries: false,
        surgeries_observations: "",
        transfusions: false,
        transfusions_observations: "",
        hepatitis: false,
        hepatitis_type: "",
        hepatitis_observations: "",
        diatebes_personal: false,
        diabetes_personal_observations: "",
        arterial_personal: false,
        arterial_personal_observations: "",
        other_diseases: "",
        blood_group: "",
        rh: "",
        smoking: false,
        smoking_observations: "",
        medicines: false,
        medicines_observations: "",
        alcoholism: false,
        alcoholism_week: "",
        alcoholism_day: "",
        exercise: false,
        exercise_observations: "",
        menarche: null,
        gestations: false,
        childbirth: null,
        caesarean: null,
        abortions: null,
        deaths: null,
        ectopic: null,
        date_gestations: moment().format('YYYY-MM-DD'),
        comments: "",
        comments_menarche: "",
        continue_menstruating: false,
        duration_menstruating: null,
        frequency_menstruating: null,
        irregular_menstrual: false,
        contraceptives: false,
        date_rule: moment().format('YYYY-MM-DD'),
        patient_id: false
      }
    }
  },

  async created() {
    await this.verifyInfo()
    await this.verifyHistory()
  },
  methods: {
    ...mapActions('clinic_patient', ['findHistory', 'createHistory']),
    ...mapActions('patient', ['findPatientOneInformation', 'createInformationGeneral', 'updateInformationGeneral', 'findPatientOneHistory', 'createHistoryMedical', 'updateHistoricPatient']),
    async updateInformation() {
      const id = this.general_information.id
      const data = this.general_information
      delete data.id
      delete data.patient_id
      const response = await this.updateInformationGeneral({id: id, data: data})
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informacion editada Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar inforamacion',
            icon: 'error',
            variant: 'warning',
          },
        });
      }
    },
    async updateHistory() {
      const id = this.history.id
      const data = this.history
      delete data.id
      delete data.patient_id
      if (this.history.irregular_menstrual === ''){
        this.history.irregular_menstrual = true
      }
      if (this.history.contraceptives === ''){
        this.history.contraceptives = true
      }
      const response = await this.updateHistoricPatient({id: id, data: data})
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Historial editado Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.verifyHistory()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al editar el historial',
            icon: 'error',
            variant: 'warning',
          },
        });
      }
    },
    async verifyInfo() {
      const response = await this.findPatientOneInformation(this.data_patient.id)
      if (response) {
        this.general_information = response
      } else {
        delete this.general_information.id
        this.general_information.patient_id = this.data_patient.id
        await this.createInformationGeneral(this.general_information);
        await this.verifyInfo()
      }
    },
    async verifyHistory() {
      const response = await this.findPatientOneHistory(this.data_patient.id)
      if (response) {
        this.history = response
      } else {
        delete this.history.id
        this.history.patient_id = this.data_patient.id
        await this.createHistoryMedical(this.history);
        await this.verifyHistory()
      }
    },
  }


}
</script>

<style scoped>

</style>
