<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-modal
        hide-footer
        id="modal-prevent-closing-lab"
        ref="my-modal"
        :title="`Subir Archivo de Biocomposiciones`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Titulo"
          >
            <b-form-input
                v-model="file_send.title"
                placeholder="Titulo"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Archivo"
          >
            <b-form-file
                @change="handleImage()"
                id="file_patient"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Comentario"
          >
            <b-form-textarea
                v-model="file_send.comment"
                placeholder="Comentario"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="createFile"
              style="width: 200px"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Guardar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-row class="mt-2 mb-3">
      <b-col
          lg="12"
      >
        <h2 style="text-align: center">{{ title }}</h2>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-3" lg="12" v-if="files.length === 0">
      <b-col

      >
        <h3 style="text-align: center">Aún no has subido ningún archivo de Estudios de Laboratorio</h3>
        <h4 style="text-align: center">Sube tu primer Archivo Haciendo Click en el botón</h4>
        <div align="center">

          <b-button
              @click="showModalFiles"
              style="width: 200px"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Subir Archivo
          </b-button>
        </div>
      </b-col>

    </b-row>
    <b-row v-else>
      <b-col
          lg="3"
      >
        <b-button
            @click="showModalFiles"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Subir Archivo
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in files" :key="idx" @click="showOneFile(a.id)">
              <b-row>
                <b-col lg="12">
                  <div align="center">
                    <feather-icon icon="FolderIcon" size="40"/>
                    <strong style="margin-left: 10px">Titulo: </strong>{{ a.title }}
                  </div>
                </b-col>
                <b-col lg="12">
                  <p style="text-align: center"><strong>Comentarios:</strong> <br>
                    {{ a.comment }}</p>
                  <p style="text-align: center">
                    {{ resHoursOdooFormatSix(a.createdAt) }}</p>

                </b-col>
              </b-row>

            </b-list-group-item>
          </b-list-group>
        </div>

      </b-col>
      <b-col cols="9" v-if="viewFile">
        <br>
        <b-row>
          <b-col cols="4">
            <div align="center">
              <h3>Información</h3><br>
              <h4>Nombre: <strong>{{ one.title }}</strong></h4>
              <h4>Comentario: <strong>{{ one.comment }}</strong></h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div align="center">
              <h3>Descargar Archivo</h3>

              <a @click="downloads(one.id, one.file,one.title, data_patient.name)"
                 style="text-align: center">
                <feather-icon icon="FolderIcon" size="100"/>
                <br>
                {{one.file}}
              </a>

            </div>
          </b-col>
          <b-col cols="4">
            <div align="center">
              <h3>Borrar archivo: </h3>
              <a style="text-align: right" @click="deleteFile(one.id)">
                <feather-icon icon="TrashIcon" style="color: darkred" size="50"/>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink,
  BRow,
  BModal,
  VBModal, BOverlay, BListGroup, BListGroupItem
} from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";

export default {
  name: "FilesLaboratories",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  mounted() {
    this.$root.$on('chargeLaboratories', async () => {
      await this.findFiles()
    })
  },
  data() {
    return {
      files: [],
      show: false,
      viewFile: false,
      one: {
        id: null,
        comment: null,
        file: null,
        is_deleted: null,
        patient_id: null,
        share_patient: null,
        type: 'laboratories',
        title: null,
      },
      file_send: {
        id: null,
        title: null,
        type: 'laboratories',
        name_file: null,
        comment: null,
        file: null,
        patient_id: 0
      }
    }
  },
  methods: {
    ...mapActions('patient', ['findPatientAllFiles', 'createPatientFiles', 'findPatientOneFiles', 'downloadFiPatientOne', 'updateFileHistory']),
    async downloads(id, file, name, partner) {
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
      }
      await this.downloadFiPatientOne(send);
    },
    async findFiles() {
      this.files = await this.findPatientAllFiles({id:this.data_patient.id, type:'laboratories'});
    },
    resetModal() {
    },
    async showOneFile(id) {
      const response = await this.findPatientOneFiles({id: id, patient: this.data_patient.id});
      if (response) {
        this.one = response
        this.viewFile = true
      }
    },
    async deleteFile(id) {
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quiers borrar el archivo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.show = true
          const send = {
            is_deleted: true
          }
          const response = await this.updateFileHistory({id: id, data: send});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Borro!',
              text: 'Su archivo se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            await this.findFiles()
            this.show = false
            this.viewFile = false
            this.file_send = {
              id: null,
              title: null,
              comment: null,
              file: null,
              patient_id: 0,
              type: 'laboratories',
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'error al borrar archivo consulto con el administrador',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },
    async createFile() {
      this.file_send.patient_id = this.data_patient.id
      if (!this.file_send.file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Archivo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }

      if (!this.file_send.title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'titulo requerido',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      await this.$bvModal.hide('modal-prevent-closing-lab')
      this.show = true
      const response = await this.createPatientFiles(this.file_send)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se guardo el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        await this.findFiles()
        this.file_send = {
          id: null,
          title: null,
          comment: null,
          type: 'laboratories',
          file: null,
          patient_id: 0
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al subir archivo consulte al administrador',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
        await this.$bvModal.show('modal-prevent-closing-lab')
      }

    },
    async handleImage() {
      const file = document.querySelector(`#file_patient`).files[0]

      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        this.file_send.file = event.target.result;
        this.file_send.name_file = file.name
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async showModalFiles() {
      await this.$bvModal.show('modal-prevent-closing-lab')


    },
  }
}
</script>

<style scoped>

</style>
