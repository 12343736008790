<template>
  <div>
    <b-row>
      <b-col
          cols="12" lg="3"
      >
        <b-button
            @click="newConsultations"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Consulta
        </b-button>
        <div class="mt-3">
          <b-list-group>
            <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in consults" :key="idx"
                               @click="viewConsultation(a.id)"> {{ resHoursOdooFormat(a.createdAt) }}(ver
              archivo)
            </b-list-group-item>
          </b-list-group>
        </div>

      </b-col>
      <b-col cols="12" lg="9" v-if="createConsultations">
        <h3 style="text-align: center;">Consulta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group
                label="Edad"
            >
              <b-form-input
                  v-model="consul.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6">

          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de consulta
              </h5>
              <flat-pickr
                  v-model="consul.date_consultation"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default"
                v-model="consul.note_evolution"
                placeholder="Nota de Evolución"
                rows="3"
            />
          </b-col>
          <b-col cols="12" class="mt-2">
            <h4>Signos Vitales y Somatometría</h4>
            <hr style="width: 100%">
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Altura(Mts)"
            >
              <b-form-input
                  v-model="consul.height"
                  placeholder="Altura(Mts)"
                  @keyup="calculateImc"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Peso"
            >
              <b-form-input
                  v-model="consul.weight"
                  @keyup="calculateImc"
                  placeholder="Peso"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="IMC"

            >
              <b-form-input
                  v-model="consul.imc"
                  @click="calculateImc"
                  placeholder="IMC"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Superficie Corporal"

            >
              <b-form-input
                  v-model="consul.body_surface"
                  @click="calculateBody"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Temperatura"
            >
              <b-form-input
                  v-model="consul.temperature"
                  placeholder="Temperatura"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" lg="3">
            <b-form-group
                label="Presión arterial"
            >
              <b-form-input
                  v-model="consul.blood_pressure"
                  placeholder="Presión arterial"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group
                label="Oxigenación"
            >
              <b-form-input
                  v-model="consul.oxygenation"
                  placeholder="Oxigenación"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group

                label="Frecuencia Cardiaca"
            >
              <b-form-input
                  v-model="consul.frecuency_cardiac"
                  placeholder="Frecuencia Cardiaca"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-group
                label="Frecuencia Respiratoria"
            >
              <b-form-input
                  v-model="consul.frecuency_respiratory"
                  placeholder="Frecuencia Respiratoria"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group
                label="Perimetro Abdominal (cm)"
            >
              <b-form-input
                  v-model="consul.abdominal_perimeter"
                  placeholder="Perimetro Abdominal (cm)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Exploracion Fisica</h4>
            <hr style="width: 100%">
            <b-row>
              <b-col cols="12">
                <b-form-textarea
                    id="textarea-default1"
                    v-model="consul.physical_exploration"
                    placeholder="Exploracion Fisica"
                    rows="3"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Resultado estudios</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default11"
                v-model="consul.result_studies"
                placeholder="Resultado estudios"
                rows="3"
            />
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default111323"
                v-model="consul.diagnosis"
                placeholder="Diagnostico"
                rows="3"
            />
          </b-col>

          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreateDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4">

              </b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeCreate()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consul.recipes">
                    <td>
                      <b-form-input
                          placeholder="Medicamento"
                          v-model="a.product"
                      />
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="a.indications"
                      />
                    </td>
                    <td>
                      <b-button v-if="!a.new_line"
                                @click="addRowMedicines(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button v-else
                                @click="removeLineMedicines(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>

          </b-col>

          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="8">

              </b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFStudiesCreate()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Hoja de estudios
                </b-button>
              </b-col>
            </b-row>
            <h3 style="text-align: center">Estudios y laboratorio</h3>
            <b-col cols="12">
              <b-form-checkbox

                  class="custom-control-dark"
                  name="check-button3"
                  @input="changeLab(!consul.order_lab.recommend_lab)"
                  switch
              />
            </b-col>
            <b-row v-if="consul.order_lab.recommend_lab">

              <b-col cols="12">
                <b-form-group
                    label="Nombre laboratorio"
                >
                  <b-form-input
                      v-model="consul.order_lab.name"
                      placeholder="Nombre laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Dirección laboratorio"
                >
                  <b-form-input
                      v-model="consul.order_lab.address"
                      placeholder="Dirección laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Telefóno laboratorio"
                >
                  <b-form-input
                      v-model="consul.order_lab.phone"
                      placeholder="Telefóno laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Observaciones"
                >
                  <b-form-textarea
                      v-model="consul.order_lab.observations"
                      placeholder="Observaciones"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card-text class="mb-0">
                  Incluye Diagnostico
                </b-card-text>
                <b-form-checkbox
                    :checked="consul.order_lab.include_diagnostic"
                    class="custom-control-dark"
                    name="check-button5"
                    @input="changeDiagnostic(!consul.order_lab.include_diagnostic)"
                    switch
                />
                <b-form-group v-if="consul.order_lab.include_diagnostic"
                              label="Diagnostico"
                >
                  <b-form-textarea
                      v-model="consul.order_lab.diagnostic"
                      placeholder="Diagnostico"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Estudio</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(c, idx) in consul.order_lab.lines">
                    <td>
                      <b-form-input
                          placeholder="Estudios"
                          v-model="c.product"
                      />
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="c.indications"
                      />
                    </td>
                    <td>
                      <b-button v-if="!c.new_line"
                                @click="addRowStudies(idx, 'create')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button v-else
                                @click="removeStudies(idx)"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>

            </b-row>
            <b-row v-else>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Analisis y Plan</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%">
                <b-form-textarea
                    id="textarea-default113"
                    v-model="consul.analysis_and_plan"
                    placeholder="Analisis y Plan"
                    rows="3"
                />
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Indicaciones Extras</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%">
                <b-form-textarea
                    id="textarea-default1133"
                    v-model="consul.extra_indications"
                    placeholder="Indicaciones Extras"
                    rows="3"
                />
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button
                @click="createConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" lg="9" v-if="updateInformation">
        <h3 style="text-align: center;">Consulta</h3>

        <b-row>
          <b-col cols="3" lg="3">
            <b-form-group
                label="Edad"
            >
              <b-form-input
                  v-model="consultations.years"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3" lg="6">

          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de consulta
              </h5>
              <flat-pickr
                  v-model="consultations.date_consultation"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h4>Nota de Evolución</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default22"
                v-model="consultations.note_evolution"
                placeholder="Nota de Evolución"
                rows="3"
            />
          </b-col>
          <b-col cols="12" class="mt-2">
            <h4>Signos Vitales</h4>
            <hr style="width: 100%">
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Altura(Mts)"
            >
              <b-form-input
                  v-model="consultations.height"
                  @keyup="calculateImcEdit"
                  placeholder="Altura(Mts)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Peso"
            >
              <b-form-input
                  v-model="consultations.weight"
                  @keyup="calculateImcEdit"
                  placeholder="Peso"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="IMC"
            >
              <b-form-input
                  v-model="consultations.imc"
                  @click="calculateImcEdit"
                  placeholder="IMC"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Superficie Corporal"
            >
              <b-form-input
                  v-model="consultations.body_surface"
                  @click="calculateImcEdit"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group
                label="Temperatura"
            >
              <b-form-input
                  v-model="consultations.temperature"
                  placeholder="Temperatura"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" lg="3">
            <b-form-group
                label="Presión arterial"
            >
              <b-form-input
                  v-model="consultations.blood_pressure"
                  placeholder="Presión arterial"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group
                label="Oxigenación"
            >
              <b-form-input
                  v-model="consultations.oxygenation"
                  placeholder="Oxigenación"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-group

                label="Frecuencia Cardiaca"
            >
              <b-form-input
                  v-model="consultations.frecuency_cardiac"
                  placeholder="Frecuencia Cardiaca"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-group
                label="Frecuencia Respiratoria"
            >
              <b-form-input
                  v-model="consultations.frecuency_respiratory"
                  placeholder="Frecuencia Respiratoria"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group
                label="Perimetro Abdominal (cm)"
            >
              <b-form-input
                  v-model="consultations.abdominal_perimeter"
                  placeholder="Perimetro Abdominal (cm)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="12">
                <h4>Exploracion Fisica</h4>
                <hr style="width: 100%">
                <b-form-textarea
                    id="textarea-default1232323"
                    v-model="consultations.physical_exploration"
                    placeholder="Exploracion Fisica"
                    rows="3"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Resultado estudios</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default1123232332"
                v-model="consultations.result_studies"
                placeholder="Resultado estudios"
                rows="3"
            />
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h4>Diagnostico</h4>
            <hr style="width: 100%">
            <b-form-textarea
                id="textarea-default112343232234"
                v-model="consultations.diagnosis"
                placeholder="Diagnostico"
                rows="3"
            />
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipeDigital()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Receta Digital
                </b-button>
              </b-col>
              <b-col cols="4">

              </b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFRecipe()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir receta
                </b-button>
              </b-col>
              <b-col cols="12">
                <h3 style="text-align: center">Medicamentos</h3>
                <table>
                  <tr>
                    <th>Producto</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(a, idx) in consultations.ClinicRecipes">
                    <td>
                      <b-form-input
                          placeholder="Medicamento"
                          v-model="a.product"
                      />
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="a.indications"
                      />
                    </td>
                    <td>
                      <b-button v-if="!a.new_line"
                                @click="addRowMedicines(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button v-else
                                @click="removeLineMedicines(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="consultations.ClinicOrderStudy" cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <b-row>
              <b-col cols="8">

              </b-col>
              <b-col cols="4">
                <b-button
                    @click="exportPDFStudies()"
                    style="width: 100%"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mr-1"
                >
                  Imprimir Hoja de estudios
                </b-button>
              </b-col>
            </b-row>
            <h3 style="text-align: center">Estudios y laboratorio</h3>
            <b-col cols="12">
              <b-form-checkbox
                  :checked="consultations.ClinicOrderStudy.recommend_lab"
                  class="custom-control-dark"
                  @input="changeLabUpdate(!consultations.ClinicOrderStudy.recommend_lab)"
                  switch
              />
            </b-col>

            <b-row v-if="consultations.ClinicOrderStudy.recommend_lab">

              <b-col cols="12">
                <b-form-group
                    label="Nombre laboratorio"
                >
                  <b-form-input
                      v-model="consultations.ClinicOrderStudy.name"
                      placeholder="Nombre laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Dirección laboratorio"
                >
                  <b-form-input
                      v-model="consultations.ClinicOrderStudy.address"
                      placeholder="Dirección laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Telefóno laboratorio"
                >
                  <b-form-input
                      v-model="consultations.ClinicOrderStudy.phone"
                      placeholder="Telefóno laboratorio"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Observaciones"
                >
                  <b-form-textarea
                      v-model="consultations.ClinicOrderStudy.observations"
                      placeholder="Observaciones"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card-text class="mb-0">
                  Incluye Diagnostico
                </b-card-text>
                <b-form-checkbox
                    :checked="consultations.ClinicOrderStudy.include_diagnostic"
                    class="custom-control-dark"
                    name="check-button"
                    @input="changeDiagnosticUpdate(!consultations.ClinicOrderStudy.include_diagnostic)"
                    switch
                />
                <b-form-group v-if="consultations.ClinicOrderStudy.include_diagnostic"
                              label="Diagnostico"
                >
                  <b-form-textarea
                      v-model="consultations.ClinicOrderStudy.diagnostic"
                      placeholder="Diagnostico"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-1">
                <table>
                  <tr>
                    <th>Estudio</th>
                    <th>Indicaciones</th>
                    <th>Acciones</th>
                  </tr>
                  <tr v-for="(b, idx) in consultations.ClinicOrderStudy.ClinicStudiesLines">
                    <td>
                      <b-form-input
                          placeholder="Estudios"
                          v-model="b.product"
                      />
                    </td>
                    <td>
                      <b-form-input
                          placeholder="Indicaciones"
                          v-model="b.indications"
                      />
                    </td>
                    <td>
                      <b-button v-if="!b.new_line"
                                @click="addRowStudies(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Agregar
                      </b-button>
                      <b-button v-else
                                @click="removeStudies(idx, 'edit')"
                                style="width: 100%"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="primary"
                                class="mr-1"
                      >
                        Borrar
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Analisis y Plan</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%">
                <b-form-textarea
                    id="textarea-default123323213"
                    v-model="consultations.analysis_and_plan"
                    placeholder="Analisis y Plan"
                    rows="3"
                />
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3 card-recipe" style="padding: 20px">
            <h3 style="text-align: center">Indicaciones Extras</h3>
            <b-row>
              <b-col cols="12">
                <h4></h4>
                <hr style="width: 100%">
                <b-form-textarea
                    id="textarea-default113"
                    v-model="consultations.extra_indications"
                    placeholder="Indicaciones Extras"
                    rows="3"
                />
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button
                @click="updateConsult()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem, BModal, BOverlay,
  BRow, VBModal, BFormCheckbox
} from "bootstrap-vue";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from 'moment';
import logo from "../../assets/images/logo/logo-1.png"
import signature from "../../assets/images/logo/signature.png"

export default {
  name: "ConsultationsPatient",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  data() {
    return {
      consultations: {
        id: null,
        height: null,
        weight: null,
        abdominal_perimeter: null,
        blood_pressure: null,
        temperature: null,
        body_surface: null,
        heart_rate: null,
        diagnosis: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        note_evolution: null,
        years: null,
        imc: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        analysis_and_plan: null,
        patient_id: null,
        appointee_id: null,
        date_consultation: null,
        ClinicRecipes: [],
        ClinicDiagnostics: [],
        ClinicOrderStudy: {}
      },
      consults: [],
      patient_id: null,
      createConsultations: false,
      updateInformation: false,
      consul: {
        height: null,
        abdominal_perimeter: null,
        diagnosis: null,
        weight: null,
        analysis_and_plan: null,
        years: null,
        body_surface: null,
        date_consultation: moment().format('YYYY-MM-DD'),
        blood_pressure: null,
        temperature: null,
        heart_rate: null,
        frecuency_respiratory: null,
        frecuency_cardiac: null,
        imc: null,
        note_evolution: null,
        oxygenation: null,
        physical_exploration: null,
        result_studies: null,
        extra_indications: null,
        patient_id: null,
        appointee_id: null,
        recipes: [
          {
            product: null,
            indications: null,
            new_line: null
          }
        ],
        diagnostics: [
          {
            name: null,
            new_line: false
          }
        ],
        order_lab: {
          name: null,
          address: null,
          phone: null,
          observations: null,
          recommend_lab: false,
          include_diagnostic: false,
          diagnostic: null,
          lines: [
            {
              product: null,
              indications: null,
              new_line: false
            }
          ]
        }

      }
    }
  },

  mounted() {
    this.$root.$on('chargeConsultation', async () => {
      // your code goes here
      this.patient_id = this.data_patient.id
      await this.getConsultations();
    })
  },
  methods: {
    ...mapActions('patient', ['findPatientAllConsultations', 'createConsultation', 'findPatientOneConsultations', 'updateConsultationStore']),
    async getConsultations() {
      this.consults = await this.findPatientAllConsultations(this.patient_id);
    },
    calculateImc() {
      if (!this.consul.height) return
      if (!this.consul.weight) return
      const multi = this.consul.height * this.consul.height
      const total = this.consul.weight / multi
      this.consul.imc = total.toFixed(2)
      const cm = this.consul.height * 100
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight
      this.consul.body_surface = total_body.toFixed(2)
    },
    calculateImcEdit() {
      if (!this.consultations.height) return
      if (!this.consultations.weight) return
      const multi = this.consultations.height * this.consultations.height
      const total = this.consultations.weight / multi
      this.consultations.imc = total.toFixed(2)
      const cm = this.consultations.height * 100
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consultations.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight
      this.consultations.body_surface = total_body.toFixed(2)
    },
    calculateBody() {
      if (!this.consul.height) return
      if (!this.consul.weight) return
      const multi = this.consul.height * this.consul.height
      const total = this.consul.weight / multi
      this.consul.imc = total.toFixed(2)
      const cm = this.consul.height * 100
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.consul.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight
      this.consul.body_surface = total_body.toFixed(2)
    },
    exportPDFRecipeCreate() {
      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: 'Desea continuar?',
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si continuar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Su archivo se va a descargar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4"
            }
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(`${this.consul.date_consultation}`, 4, 3);
            doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
            doc.text(`Diagnóstico: ${this.consul.diagnosis}`, 1.5, 4);
            doc.autoTable({
              columns: [
                {header: 'Medicamento', dataKey: 'product'},
                {header: 'Indicaciones', dataKey: 'indications'},

              ],
              startY: 4.5,
              headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            })


            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Puede quitar medicamentos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        return
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(`${this.dateStringEs(this.consul.date_consultation)}`, 4, 3);
      doc.text(`Edad: ${this.consul.years} Años`, 1.5, 3.5);
      doc.text(`Diagnóstico: ${this.consul.diagnosis}`, 1.5, 4);
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consul.recipes,
      })


      doc.save(`${a}-${b}.pdf`);
    },
    async exportPDFRecipeCreateDigital() {

      if (this.consul.recipes.length > 10) {
        this.$swal({
          title: 'Desea continuar?',
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si continuar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Su archivo se va a descargar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4"
            }
            var doc = new jsPDF(options);
            const a = this.data_patient.name;
            const b = this.consul.date_consultation;

            doc.setFontSize(10);
            const img = new Image()
            img.src = logo
            doc.addImage(img, 'png', 8, 1, 4, 2)
            doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
            doc.text(`Dia de consulta: ${this.consul.date_consultation}`, 1, 4.5);
            doc.text(`Edad: ${this.consul.years} Años`, 1, 5);
            doc.text(`Diagnóstico: ${this.consul.diagnosis}`, 1, 5.5);
            doc.autoTable({
              columns: [
                {header: 'Medicamento', dataKey: 'product'},
                {header: 'Indicaciones', dataKey: 'indications'},

              ],
              startY: 6,
              headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            })

            if (this.data_patient.ResPartnerAppointment.HrEmployee) {
              const img2 = new Image()
              img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature
              doc.addImage(img2, 'png', 8, 12, 4, 2)
            }
            doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Puede quitar medicamentos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        return
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(8);
      const img = new Image()
      img.src = logo
      doc.addImage(img, 'png', 8, 1, 4, 2)
      doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
      doc.text(`Dia de consulta: ${this.consul.date_consultation}`, 1, 4.5);
      doc.text(`Edad: ${this.consul.years} Años`, 1, 5);
      doc.text(`Diagnóstico: ${this.consul.diagnosis}`, 1, 5.5);
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 6,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consul.recipes,
      })

      if (this.data_patient.ResPartnerAppointment.HrEmployee) {
        const img2 = new Image()
        img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature
        doc.addImage(img2, 'png', 8, 12, 4, 2)
      }

      doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
      doc.save(`${a}-${b}.pdf`);
    },
    exportPDFRecipeDigital() {

      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: 'Desea continuar?',
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si continuar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Su archivo se va a descargar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4"
            }
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            const img = new Image()
            img.src = logo
            doc.addImage(img, 'png', 8, 1, 4, 2)
            doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
            doc.text(`Dia de consulta: ${this.consultations.date_consultation}`, 1, 4.5);
            doc.text(`Edad: ${this.consultations.years} Años`, 1, 5);
            doc.text(`Diagnóstico: ${this.consultations.diagnosis}`, 1, 5.5);
            doc.autoTable({
              columns: [
                {header: 'Medicamento', dataKey: 'product'},
                {header: 'Indicaciones', dataKey: 'indications'},

              ],
              startY: 6,
              headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
              bodyStyles: {fontSize: 8},
              body: this.consultations.ClinicRecipes,
            })

            if (this.data_patient.ResPartnerAppointment.HrEmployee) {
              const img2 = new Image()
              img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature
              doc.addImage(img2, 'png', 8, 12, 4, 2)
            }
            doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Puede quitar medicamentos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        return
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(10);
      const img = new Image()
      img.src = logo
      doc.addImage(img, 'png', 8, 1, 4, 2)
      doc.text(`Nombre: ${this.data_patient.name}`, 1, 4);
      doc.text(`Dia de consulta: ${this.consultations.date_consultation}`, 1, 4.5);
      doc.text(`Edad: ${this.consultations.years} Años`, 1, 5);
      doc.text(`Diagnóstico: ${this.consultations.diagnosis}`, 1, 5.5);
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 6,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consultations.ClinicRecipes,
      })

      if (this.data_patient.ResPartnerAppointment.HrEmployee) {
        const img2 = new Image()
        img2.src = this.data_patient.ResPartnerAppointment.HrEmployee.signature
        doc.addImage(img2, 'png', 8, 12, 4, 2)
      }
      doc.text(`${this.data_patient.ResPartnerAppointment.name}`, 8, 15);
      doc.save(`${a}-${b}.pdf`);

    },
    exportPDFRecipe() {
      if (this.consultations.ClinicRecipes.length > 10) {
        this.$swal({
          title: 'Desea continuar?',
          text: "Su receta excede el numero de medicamentos esto puede desfasar la receta",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si continuar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Su archivo se va a descargar',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const options = {
              orientation: "p",
              unit: "cm",
              format: "a4"
            }
            var doc = new jsPDF(options);

            doc.setFontSize(10);
            doc.text(`${this.data_patient.name}`, 5, 2);
            doc.text(`${this.dateStringEs(this.consultations.date_consultation)}`, 4, 3);
            doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);
            doc.text(`Diagnóstico: ${this.consultations.diagnosis}`, 1.5, 4);

            doc.autoTable({
              columns: [
                {header: 'Medicamento', dataKey: 'product'},
                {header: 'Indicaciones', dataKey: 'indications'},

              ],
              startY: 4.5,
              headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
              bodyStyles: {fontSize: 8},
              body: this.consul.recipes,
            })


            doc.save(`${a}-${b}.pdf`);
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Puede quitar medicamentos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        return
      }
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = this.consul.date_consultation;

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 5, 2);
      doc.text(`${this.dateStringEs(this.consultations.date_consultation)}`, 4, 3);
      doc.text(`Edad: ${this.consultations.years} Años`, 1.5, 3.5);
      doc.text(`Diagnóstico: ${this.consultations.diagnosis}`, 1.5, 4);

      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 4.5,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: this.consultations.ClinicRecipes,
      })


      doc.save(`${a}-${b}.pdf`);

    },

    exportPDFStudiesCreate() {
      var doc = new jsPDF('p', 'pt');
      doc.text(`Estudios`, 40, 40);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.text(`Laboratorio: ${this.consul.order_lab.name}`, 40, 100);
      doc.text(`Direcció: ${this.consul.order_lab.address}`, 40, 130);
      doc.text(`Telefono: ${this.consul.order_lab.phone}`, 40, 160);
      doc.text(`Doctor: ${this.data_patient.ResPartnerAppointment.name}`, 40, 190);
      doc.text(`Observaciones: ${this.consul.order_lab.observations}`, 40, 220);
      doc.autoTable({
        columns: [
          {header: 'Estudio', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},
        ],
        body: this.consul.order_lab.lines,
        margin: {top: 250},
      })

      doc.save(`${this.data_patient.name}-${this.consultations.date_consultation}.pdf`);
    },

    exportPDFStudies() {
      var doc = new jsPDF('p', 'pt');
      doc.text(`Estudios`, 40, 40);
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.text(`Laboratorio: ${this.consultations.ClinicOrderStudy.name}`, 40, 100);
      doc.text(`Direcció: ${this.consultations.ClinicOrderStudy.address}`, 40, 130);
      doc.text(`Telefono: ${this.consultations.ClinicOrderStudy.phone}`, 40, 160);
      doc.text(`Doctor: ${this.data_patient.ResPartnerAppointment.name}`, 40, 190);
      doc.text(`Observaciones: ${this.consultations.ClinicOrderStudy.observations}`, 40, 220);
      doc.autoTable({
        columns: [
          {header: 'Estudio', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},
        ],
        body: this.consultations.ClinicOrderStudy.ClinicStudiesLines,
        margin: {top: 250},
      })

      doc.save(`${this.data_patient.name}-${this.consultations.date_consultation}.pdf`);
    },
    newConsultations() {
      this.consultations.ClinicOrderStudy.recommend_lab = false
      this.createConsultations = true
      this.updateInformation = false


    },
    changeDiagnostic(state) {
      this.consul.order_lab.include_diagnostic = state
    },
    changeDiagnosticUpdate(state) {
      this.consultations.ClinicOrderStudy.include_diagnostic = state
    },
    async updateConsult() {
      const response = await this.updateConsultationStore(this.consultations)
      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se edito correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getConsultations()
        this.createConsultations = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
    },
    async viewConsultation(id) {
      this.consultations = {}
      this.updateInformation = false
      this.createConsultations = false
      this.consultations = await this.findPatientOneConsultations(id)
      let recipes = this.consultations.ClinicRecipes
      let diagnostics = this.consultations.ClinicDiagnostics
      let lines = this.consultations.ClinicOrderStudy.ClinicStudiesLines
      this.consultations.ClinicRecipes = []
      this.consultations.ClinicDiagnostics = []
      this.consultations.ClinicOrderStudy.ClinicStudiesLines = []
      for (const a of recipes) {
        this.consultations.ClinicRecipes.push({
          product: a.product,
          indications: a.indications,
          clinic_consultations_id: a.clinic_consultations_id,
          new_line: true
        })
      }
      this.consultations.ClinicRecipes.push({
        product: null,
        indications: null,
        clinic_consultations_id: this.consultations.id,
        new_line: false
      })
      for (const b of diagnostics) {
        this.consultations.ClinicDiagnostics.push({
          name: b.name,
          clinic_consultations_id: b.clinic_consultations_id,
          new_line: true
        })
      }
      this.consultations.ClinicDiagnostics.push({
        name: null,
        clinic_consultations_id: this.consultations.id,
        new_line: false
      })
      for (const c of lines) {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: c.product,
          indications: c.indications,
          clinic_order_studies_id: c.clinic_order_studies_id,
          new_line: false
        })
      }
      this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
        product: null,
        indications: null,
        clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
        new_line: false
      })
      this.updateInformation = true
    },
    changeLab(state) {
      this.consul.order_lab.recommend_lab = state
    },
    changeLabUpdate(state) {
      this.consultations.ClinicOrderStudy.recommend_lab = state
    },
    async createConsult() {
      this.consul.appointee_id = this.data_patient.appointee_id
      this.consul.patient_id = this.data_patient.id
      const response = await this.createConsultation(this.consul)
      if (response === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se creo correctamente`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getConsultations()
        this.createConsultations = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${response}`,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
    },

    addRowMedicines(idx, type) {
      if (type === 'create') {
        this.consul.recipes[idx].new_line = true
        this.consul.recipes.push({
          product: null,
          indications: null,
          new_line: false
        })
      } else {
        this.consultations.ClinicRecipes[idx].new_line = true
        this.consultations.ClinicRecipes.push({
          product: null,
          indications: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false
        })
      }

    },
    removeLineMedicines(id, type) {
      if (type === 'create') {
        this.consul.recipes.splice(id, 1)
      } else {
        this.consultations.ClinicRecipes.splice(id, 1)
      }

    },
    addRowStudies(idx, type) {
      if (type === 'create') {
        this.consul.order_lab.lines[idx].new_line = true
        this.consul.order_lab.lines.push({
          product: null,
          indications: null,
          new_line: false
        })
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines[idx].new_line = true
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.push({
          product: null,
          indications: null,
          clinic_order_studies_id: this.consultations.ClinicOrderStudy.id,
          new_line: false,
        })
      }

    },
    removeStudies(id, type) {
      if (type === 'create') {
        this.consul.order_lab.lines.splice(id, 1)
      } else {
        this.consultations.ClinicOrderStudy.ClinicStudiesLines.splice(id, 1)
      }

    },
    addRowDiagnostics(idx, type) {
      if (type === 'create') {
        this.consul.diagnostics[idx].new_line = true
        this.consul.diagnostics.push({
          name: null,
          new_line: false
        })
      } else {
        this.consultations.ClinicDiagnostics.push({
          name: null,
          clinic_consultations_id: this.consultations.id,
          new_line: false
        })
      }

    },
    removeDiagnostics(id, type) {
      if (type === 'create') {
        this.consul.diagnostics.splice(id, 1)
      } else {
        this.consultations.ClinicDiagnostics.splice(id, 1)
      }

    },
  }
}
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

</style>
