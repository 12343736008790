<template>
  <div>
    <b-row>
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-col
          lg="12"
      >
        <b-button
            @click="viewScheme()"
            style="width: 200px"
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
        >
          Crear Esquema
        </b-button>
        <div class="mt-3 mb-3">
          <b-row>
            <b-col cols="4" v-for="(a, idx) in list_schemes" :key="idx" class="mt-2">
              <b-list-group>
                <b-list-group-item style="cursor:pointer;"

                >
                  <b-row>
                    <b-col cols="6" @click="viewId(a.id, data_patient.id)">
                      {{ a.Chemotherapy.name }} (ver
                      archivo {{ resHoursOdooFormat(a.createdAt) }})
                    </b-col>
                    <b-col cols="6">
                      <div align="right">
                        <a style="cursor: pointer">
                          <feather-icon v-b-tooltip.hover @click="deleteScheme(a.id)"
                                        title="Borrar" icon="TrashIcon" style="color: red" size="25"/>
                        </a>
                      </div>


                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>

          </b-row>

        </div>

      </b-col>
      <b-col cols="12" v-if="scheme_active">
        <h3 style="text-align: center;">Esquema</h3>

        <b-row>

          <b-col cols="12">
            <h4>Datos de quimioterapia</h4>
            <hr style="width: 100%">
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Esquema"
                label-for="h-email"
            >
              <v-select
                  @input="selectScheme('create')"
                  v-model="select_scheme"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="schemes"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Sexo(Genero)"
                label-for="h-email"
            >
              <v-select
                  @input="selectGender('create')"
                  v-model="select_gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="genders"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Edad"
            >
              <b-form-input
                  v-model="scheme.age"
                  @input="selectGender('create')"
                  type="number"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Altura(Mts)"
            >
              <b-form-input
                  v-model="scheme.size"
                  placeholder="Altura(Mts)"
                  @input="calculateBody"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Peso(Kilos)"
            >
              <b-form-input
                  v-model="scheme.weight"
                  placeholder="Peso"
                  @input="calculateBody"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Superficie Corporal"

            >
              <b-form-input
                  v-model="scheme.body_surface"
                  @click="calculateBody"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Motivo Tx"
                label-for="h-email"
            >
              <v-select
                  @input="selectReason('create')"
                  v-model="select_reason"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="reasons"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Diagnostico"
            >
              <b-form-input
                  v-model="scheme.diagnosis"
                  placeholder="Diagnostico"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
                label="Periocidad"
            >
              <b-form-input
                  v-model="scheme.cycle"
                  placeholder="Periocidad"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
                label="Total de ciclos"
            >
              <b-form-input
                  v-model="scheme.total_cycle"
                  placeholder="Total de ciclos"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de Inicio
              </h5>
              <flat-pickr
                  v-model="scheme.date_initial"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                    },
                    months: {
                      shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                      longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                    }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de Final
              </h5>
              <flat-pickr
                  v-model="scheme.date_end"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                      firstDayOfWeek: 1,
                      weekdays: {
                        shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                        longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                      },
                      months: {
                        shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                        longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                      }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Comentarios esquema"
            >
              <b-form-textarea
                  v-model="scheme.comments"
                  placeholder="Comentarios"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3 style="text-align: center">Hoja de Quimioterapia</h3>
            <div v-if="select_table" style="overflow-x: scroll">
              <table style="width: 100%;">
                <thead>
                <tr>
                  <th scope="col">Orden</th>
                  <th scope="col">Premedicac....</th>
                  <th scope="col">Medicamento</th>
                  <th scope="col">Dosis</th>
                  <th scope="col">U. Dosis</th>
                  <th scope="col">Dosis total</th>
                  <th scope="col">U. Dosis</th>
                  <th scope="col">Via. Admin</th>
                  <th scope="col">VD</th>
                  <th scope="col">Solución</th>
                  <th scope="col">Tiempo de Inf.</th>
                  <th scope="col">Unidad I.</th>
                  <th scope="col">Dias Tx</th>
                  <th scope="col">Creatinina</th>
                  <th scope="col">Auc</th>
                  <th scope="col">Tasa filtración</th>
                  <th scope="col">Dosis de Carboplatino</th>

                  <th scope="col">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(a, idx) in scheme.chemotherapy_lines">
                  <td data-label="Orden">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.order"
                    />
                  </td>
                  <td data-label="Premedicac....">
                    <b-form-checkbox style="margin-left: 40px" class="margin-top-select"
                                     v-model="a.premedication"
                    >
                    </b-form-checkbox>
                  </td>
                  <td data-label="Medicamento">
                    <v-select
                        v-model="a.select_product" class="margin-top-select font-black-result"
                        @input="selectProduct('create', idx)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="product"
                    />

                  </td>
                  <td data-label="Dosis">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.dose"
                        @input="selectDose('create', idx)"
                    />

                  </td>
                  <td data-label="U. Dosis">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectDose('create', idx)"
                              v-model="a.select_dose"
                              :options="dose"
                    />

                  </td>
                  <td data-label="Dosis Total">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.total_dose"
                    />
                  </td>
                  <td data-label="U. Dosis">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectDose('create', idx)"
                              v-model="a.select_dose"
                              :options="dose"
                    />

                  </td>
                  <td data-label="Via. Admin">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              v-model="a.select_vias"
                              @input="selectVias('create', idx)"
                              :options="vias"
                    />
                  </td>
                  <td data-label="VD">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.distribution_volume"
                    />
                  </td>
                  <td data-label="Solución">

                    <v-select style="width: 100%" class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectSolution('create', idx)"
                              v-model="a.select_solution"
                              :options="select_type_solutions"
                    />

                  </td>
                  <td data-label="Tiempo de Inf.">

                    <b-form-input
                        class="width-table-input"
                        v-model="a.infusion_time"
                    />
                  </td>
                  <td data-label="Unidad I.">

                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectTime('create', idx)"
                              v-model="a.select_time"
                              :options="times"
                    />

                  </td>
                  <td data-label="Dias Tx">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.treatment_days"
                    />
                  </td>
                  <td data-label="Creatinina">
                    <b-form-input
                        type="number"
                        class="width-table-input"
                        @input="creatinineVerify('create',idx)"
                        v-model="a.creatinine"
                    />
                  </td>
                  <td data-label="Auc">
                    <b-form-input
                        type="text"
                        class="width-table-input"
                        maxlength="1"
                        @input="verifyAuc('create',idx)"
                        v-model="a.auc"
                    />
                  </td>
                  <td data-label="Tasa filtración">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.filtration_rate"
                    />
                  </td>
                  <td data-label="Dosis de Carboplatino">
                    <b-form-input
                        class="width-table-input"
                        v-model="a.dose_carboplatino"
                    />
                  </td>

                  <td data-label="Acciones">
                    <b-button v-if="!a.new_line"
                              @click="addRowMedicines(idx, 'create')"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                    >
                      Agregar
                    </b-button>
                    <b-button v-else
                              @click="removeLineMedicines(idx, 'create')"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                    >
                      Borrar
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </b-col>

          <b-col v-if="select_table" cols="12" class="mt-3">
            <b-button
                @click="createScheme()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-if="scheme_active_update">
        <h3 style="text-align: center;">Esquema</h3>

        <b-row>

          <b-col cols="4">
            <b-button
                @click="exportPDFScheme()"
                style="width: 50%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Imprimir
            </b-button>
          </b-col>
          <b-col cols="12" class="mt-4">
            <h4>Datos de quimioterapia</h4>
            <hr style="width: 100%">
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Esquema"
                label-for="h-email"
            >
              <v-select
                  @input="selectScheme('edit')"
                  v-model="select_scheme"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="schemes"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Sexo(Genero)"
                label-for="h-email"
            >
              <v-select
                  @input="selectGender('edit')"
                  v-model="select_gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="genders"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Edad"
            >
              <b-form-input
                  v-model="scheme_update.age"
                  @input="selectGender('edit')"
                  type="number"
                  placeholder="Edad"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Altura(Mts)"
            >
              <b-form-input
                  v-model="scheme_update.size"
                  placeholder="Altura(Mts)"
                  @input="calculateBodyEdit"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Peso(Kilos)"
            >
              <b-form-input
                  v-model="scheme_update.weight"
                  placeholder="Peso"
                  @input="calculateBodyEdit"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
                label="Superficie Corporal"

            >
              <b-form-input
                  v-model="scheme_update.body_surface"
                  @click="calculateBodyEdit"
                  placeholder="Superficie Corporal"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Motivo Tx"
                label-for="h-email"
            >
              <v-select
                  @input="selectReason('edit')"
                  v-model="select_reason"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="reasons"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Diagnostico"
            >
              <b-form-input
                  v-model="scheme_update.diagnosis"
                  placeholder="Diagnostico"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
                label="Periocidad"
            >
              <b-form-input
                  v-model="scheme_update.cycle"
                  placeholder="Periocidad"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
                label="Total de ciclos"
            >
              <b-form-input
                  v-model="scheme_update.total_cycle"
                  placeholder="Total de ciclos"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de Inicio
              </h5>
              <flat-pickr
                  v-model="scheme_update.date_initial"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                      longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                    },
                    months: {
                      shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                      longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                    }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <h5 class="font-weight-bold">
                Dia de Final
              </h5>
              <flat-pickr
                  v-model="scheme_update.date_end"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
                      firstDayOfWeek: 1,
                      weekdays: {
                        shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                        longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                      },
                      months: {
                        shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                        longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                      }}}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Comentarios esquema"
            >
              <b-form-textarea
                  v-model="scheme_update.comments"
                  placeholder="Comentarios"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3 style="text-align: center">Hoja de Quimioterapia</h3>
            <div v-if="select_table_update" style="overflow-x: scroll">
              <table>
                <thead>
                <tr>
                  <th scope="col">Orden</th>
                  <th scope="col">Premedicac....</th>
                  <th scope="col">Medicamento</th>
                  <th scope="col">Dosis</th>
                  <th scope="col">U. Dosis</th>
                  <th scope="col">Dosis total</th>
                  <th scope="col">U. Dosis</th>
                  <th scope="col">Via. Admin</th>
                  <th scope="col">VD</th>
                  <th scope="col">Solución</th>
                  <th scope="col">Tiempo de Inf.</th>
                  <th scope="col">Unidad I.</th>
                  <th scope="col">Dias Tx</th>
                  <th scope="col">Creatinina</th>
                  <th scope="col">Auc</th>
                  <th scope="col">Tasa filtración</th>
                  <th scope="col">Dosis de Carboplatino</th>

                  <th scope="col">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(b, idx) in new_lines">
                  <td data-label="Orden">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.order"
                    />
                  </td>
                  <td data-label="Premedicac....">
                    <b-form-checkbox style="margin-left: 40px" class="margin-top-select font-black-result"
                                     v-model="b.premedication"
                    >
                    </b-form-checkbox>
                  </td>
                  <td data-label="Medicamento">
                    <v-select
                        v-model="b.select_product" class="margin-top-select font-black-result"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        @input="selectProduct('edit', idx)"
                        :options="product"
                    />

                  </td>
                  <td data-label="Dosis">
                    <b-form-input
                        class="width-table-input"
                        @input="selectDose('edit', idx)"
                        v-model="b.dose"
                    />

                  </td>
                  <td data-label="U. Dosis">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectDose('edit', idx)"
                              v-model="b.select_dose"
                              :options="dose"
                    />

                  </td>
                  <td data-label="Dosis total">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.total_dose"
                    />
                  </td>
                  <td data-label="U. Dosis">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectDose('edit', idx)"
                              v-model="b.select_dose"
                              :options="dose"
                    />

                  </td>
                  <td data-label="Via. Admin">
                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              v-model="b.select_vias"
                              @input="selectVias('edit', idx)"
                              :options="vias"
                    />
                  </td>
                  <td data-label="VD">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.distribution_volume"
                    />
                  </td>
                  <td data-label="Solución">

                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectSolution('edit', idx)"
                              v-model="b.select_solution"
                              :options="select_type_solutions"
                    />

                  </td>
                  <td data-label="Tiempo de Inf.">

                    <b-form-input
                        class="width-table-input"
                        v-model="b.infusion_time"
                    />
                  </td>
                  <td data-label="Unidad I.">

                    <v-select class="margin-top-select font-black-result"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="value"
                              @input="selectTime('edit', idx)"
                              v-model="b.select_time"
                              :options="times"
                    />

                  </td>
                  <td data-label="Dias Tx">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.treatment_days"
                    />
                  </td>
                  <td data-label="Creatinina">
                    <b-form-input
                        class="width-table-input"
                        @input="creatinineVerify('edit',idx)"
                        v-model="b.creatinine"
                    />
                  </td>
                  <td data-label="Auc">
                    <b-form-input
                        class="width-table-input"
                        @input="verifyAuc('edit',idx)"
                        v-model="b.auc"
                    />
                  </td>
                  <td data-label="Tasa filtración">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.filtration_rate"
                    />
                  </td>
                  <td data-label="Dosis de Carboplatino">
                    <b-form-input
                        class="width-table-input"
                        v-model="b.dose_carboplatino"
                    />
                  </td>

                  <td data-label="Acciones">
                    <b-button v-if="!b.new_line"
                              @click="addRowMedicines(idx, 'edit')"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                    >
                      Agregar
                    </b-button>
                    <b-button v-else
                              @click="removeLineMedicines(idx, 'edit')"
                              style="width: 100%"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="primary"
                              class="mr-1"
                    >
                      Borrar
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </b-col>

          <b-col v-if="select_table_update" cols="12" class="mt-3">
            <b-button
                @click="updateScheme()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BForm,
  BCardText,
  BLink,
  BAvatar,
  BFormFile,
  BFormSelect,
  BFormRadio,
  BModal,
  BOverlay,
  BListGroup,
  BListGroupItem, VBModal, BFormCheckbox,  VBTooltip,
} from 'bootstrap-vue'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  name: "PatientChemotherapy",
  props: {
    title: String,
    data_patient: Object
  },
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox, vSelect,VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      select_reason: {id: '', value: ''},
      select_gender: {id: '', value: ''},
      select_scheme: {id: '', value: ''},
      select_table: false,
      select_table_update: false,
      medical_name: null,
      product: [],
      genders: [
        {
          id: 'F', value: 'Femenino'
        },
        {
          id: 'M', value: 'Masculino'
        }
      ],
      select_type_solutions: [

        {
          id: 'Salina', value: 'Salina'
        },
        {
          id: 'Glucosa', value: 'Glucosa'
        },

      ],
      reasons: [],
      schemes: [],
      list_schemes: [],
      scheme_active: false,
      scheme_active_update: false,
      show: false,
      dose: [],
      times: [],
      vias: [],
      new_lines: [
        {
          order: null,
          select_dose: {id: '', value: ''},
          select_product: {id: '', value: ''},
          select_time: {id: '', value: ''},
          select_solution: {id: '', value: ''},
          select_vias: {id: '', value: ''},
          premedication: false,
          product_id: null,
          dose: null,
          total_dose: null,
          chemotherapy_unit_dose_id: null,
          type_solution: null,
          chemotherapy_via_administration_id: null,
          distribution_volume: null,
          infusion_time: null,
          chemotherapy_unit_time_id: null,
          treatment_days: null,
          creatinine: null,
          filtration_rate: null,
          dose_carboplatino: null,
          auc: null,
          chemotherapy_assign_id: null,
          new_line: null
        }
      ],
      scheme_update: {
        id: null,
        diagnosis: null,
        comments: null,
        gender: null,
        age: null,
        weight: null,
        total_dose: null,
        size: null,
        body_surface: null,
        cycle: null,
        total_cycle: null,
        date_initial: null,
        date_end: null,
        chemotherapy_treatment_reason_id: null,
        patient_id: null,
        appointee_id: null,
        chemotherapy_id: null,
        chemotherapy_lines: []
      },
      scheme: {
        diagnosis: null,
        comments: null,
        gender: null,
        age: null,
        weight: null,
        size: null,
        body_surface: null,
        cycle: null,
        total_cycle: null,
        date_initial: null,
        date_end: null,
        chemotherapy_treatment_reason_id: null,
        patient_id: null,
        appointee_id: null,
        chemotherapy_id: null,
        chemotherapy_lines: [
          {
            order: null,
            premedication: false,
            select_dose: {id: '', value: ''},
            select_product: {id: '', value: ''},
            select_time: {id: '', value: ''},
            select_vias: {id: '', value: ''},
            select_solution: {id: '', value: ''},
            product_id: null,
            dose: null,
            type_solution: null,
            total_dose: null,
            chemotherapy_unit_dose_id: null,
            chemotherapy_via_administration_id: null,
            distribution_volume: null,
            infusion_time: null,
            chemotherapy_unit_time_id: null,
            treatment_days: null,
            creatinine: null,
            filtration_rate: null,
            dose_carboplatino: null,
            auc: null,
            chemotherapy_assign_id: null,
            new_line: null
          }
        ]
      },

    }
  },
  mounted() {
    this.$root.$on('chargeSchema', async () => {
      // your code goes here
      await this.listSchemes()
      await this.listReasons()
      await this.listDose()
      await this.listTimes()
      await this.listViaAdmin()
      await this.findProducts();
      await this.getAllIdSchemes();
    })
  },
  /*  async created() {
      await this.listSchemes()
      await this.listReasons()
      await this.listDose()
      await this.listTimes()
      await this.listViaAdmin()
      await this.findProducts();
      await this.getAllIdSchemes();
    },*/
  methods: {
    ...mapActions('schemes', ['getSchemes', 'getReason', 'getSchemesOne', 'getDose', 'getTimes', 'getVias', 'createSchemeServer', 'getSchemesPatient', 'getSchemesPatientOne', 'updateSchemeServer', 'deleteSchemeStore']),
    ...mapActions('treatment', ['findProduct']),
    verifyAuc(type, idx) {
      if (type === 'create') {
        const value = this.scheme.chemotherapy_lines[idx].auc

        if (parseInt(value) >= 1 && parseInt(value) <= 7) {
          this.scheme.chemotherapy_lines[idx].auc = value
          this.scheme.chemotherapy_lines[idx].dose_carboplatino = this.formCarbolatino(value, this.scheme.chemotherapy_lines[idx].filtration_rate)
        } else {
          this.scheme.chemotherapy_lines[idx].auc = ''
        }
      } else {
        const value = this.new_lines[idx].auc

        if (parseInt(value) >= 1 && parseInt(value) <= 7) {
          console.log(value)
          this.new_lines[idx].auc = value
          this.new_lines[idx].dose_carboplatino = this.formCarbolatino(value, this.new_lines[idx].filtration_rate)
        } else {
          this.new_lines[idx].auc = ''
        }
      }


    },
    selectGender(type) {
      if (type === 'create') {
        this.scheme.gender = this.select_gender.id
        for (const a of this.scheme.chemotherapy_lines) {
          if (a.creatinine > 0) {
            a.filtration_rate = this.formFiltration(this.scheme.age, this.scheme.weight, this.scheme.gender, a.creatinine)
            if (a.auc > 0) {
              a.dose_carboplatino = this.formCarbolatino(a.auc, a.filtration_rate)
            }
          }
        }
      } else {
        this.scheme_update.gender = this.select_gender.id
        for (const a of this.new_lines) {
          if (a.creatinine > 0) {
            a.filtration_rate = this.formFiltration(this.scheme_update.age, this.scheme_update.weight, this.scheme_update.gender, a.creatinine)
            if (a.auc > 0) {
              a.dose_carboplatino = this.formCarbolatino(a.auc, a.filtration_rate)
            }
          }
        }
      }
    },
    creatinineVerify(type, idx) {
      if (type === 'create') {
        const value = this.scheme.chemotherapy_lines[idx].creatinine
        this.scheme.chemotherapy_lines[idx].filtration_rate = this.formFiltration(this.scheme.age, this.scheme.weight, this.scheme.gender, value)
        if (this.scheme.chemotherapy_lines[idx].auc > 0) {
          this.scheme.chemotherapy_lines[idx].dose_carboplatino = this.formCarbolatino(this.scheme.chemotherapy_lines[idx].auc, this.scheme.chemotherapy_lines[idx].filtration_rate)
        }


      } else {
        const value = this.new_lines[idx].creatinine
        if (!value)return
        this.new_lines[idx].filtration_rate = this.formFiltration(this.scheme_update.age, this.scheme_update.weight, this.scheme_update.gender, value)
        if (this.new_lines[idx].auc > 0) {
          this.new_lines[idx].dose_carboplatino = this.formCarbolatino(this.new_lines[idx].auc, this.new_lines[idx].filtration_rate)
        }
      }

    },

    calculateBody() {

      if (!this.scheme.weight) return;
      this.selectGender('create')
      if (!this.scheme.size) return
      const cm = this.scheme.size * 100
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.scheme.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight

      this.scheme.body_surface = total_body.toFixed(2)
      this.calculateTotalDose('create')
    },

    calculateBodyEdit() {
      if (!this.scheme_update.weight) return
      this.selectGender('edit')
      if (!this.scheme_update.size) return
      const cm = this.scheme_update.size * 100
      const multi_height = Math.pow(cm, 0.725);
      const multi_weight = Math.pow(this.scheme_update.weight, 0.425);
      const total_body = 0.007184 * multi_height * multi_weight
      this.scheme_update.body_surface = total_body.toFixed(2)
      this.calculateTotalDose('edit')
    },
    async viewId(id, patient_id) {
      const response = await this.getSchemesPatientOne({id, patient_id});
      this.select_scheme.id = response.Chemotherapy.id
      this.select_scheme.value = response.Chemotherapy.name
      this.select_gender.id = response.gender
      if (response.gender === 'F') {
        this.select_gender.value = 'Femenino'
      } else {
        this.select_gender.value = 'Masculino'
      }

      this.select_reason.id = response.ChemotherapyTreatmentReason.id
      this.select_reason.value = response.ChemotherapyTreatmentReason.name
      this.scheme_update = response
      this.scheme_active = false
      this.scheme_active_update = true
      this.select_table_update = true
      let lines = []
      for (const a of response.ChemotherapyLinesAssigns) {
        lines.push({
          order: a.order,
          total_dose: a.total_dose,
          premedication: a.premedication,
          product_id: a.product_id,
          dose: a.dose,
          filtration_rate: a.filtration_rate,
          dose_carboplatino: a.dose_carboplatino,
          ca: a.ca,
          chemotherapy_unit_dose_id: a.chemotherapy_unit_dose_id,
          select_dose: {
            id: a.chemotherapy_unit_dose_id,
            value: a.ChemotherapyUnitDose.name
          },
          select_time: {
            id: a.chemotherapy_unit_time_id,
            value: a.ChemotherapyUnitTime.name
          },
          select_vias: {
            id: a.chemotherapy_via_administration_id,
            value: a.ChemotherapyViaAdmin.name
          },
          select_product: {
            id: a.product_id,
            value: a.ProductTemplate.name
          },
          select_solution: {
            id: a.type_solution,
            value: a.type_solution
          },
          chemotherapy_via_administration_id: a.chemotherapy_via_administration_id,
          distribution_volume: a.distribution_volume,
          type_solution: a.type_solution,
          infusion_time: a.infusion_time,
          chemotherapy_unit_time_id: a.chemotherapy_unit_time_id,
          treatment_days: a.treatment_days,
          creatinine: a.creatinine,
          multiply: a.multiply,
          auc: a.auc,
          chemotherapy_assign_id: a.chemotherapy_assign_id,
          new_line: true
        })
      }

      this.new_lines = lines
      this.new_lines.push({
        total_dose: null,
        order: null,
        premedication: false,
        product_id: null,
        dose: null,
        chemotherapy_unit_dose_id: null,
        chemotherapy_via_administration_id: null,
        distribution_volume: null,
        infusion_time: null,
        chemotherapy_unit_time_id: null,
        treatment_days: null,
        creatinine: null,
        multiply: null,
        type_solution: null,
        select_dose: {id: '', value: ''},
        select_solution: {id: '', value: ''},
        select_product: {id: '', value: ''},
        select_time: {id: '', value: ''},
        select_vias: {id: '', value: ''},
        auc: null,
        chemotherapy_assign_id: null,
        new_line: false
      })
      this.medical_name = this.scheme_update.HrEmployee.name
      delete this.scheme_update.Chemotherapy
      delete this.scheme_update.ChemotherapyLinesAssigns
      delete this.scheme_update.ChemotherapyTreatmentReason
      delete this.scheme_update.ResPartnerAppointment
      this.selectGender('edit')
      this.calculateTotalDose('edit')
    },
    async listSchemes() {
      const response = await this.getSchemes();
      for (const a of response) {
        this.schemes.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getAllIdSchemes() {
      this.list_schemes = []
      this.list_schemes = await this.getSchemesPatient(this.data_patient.id);

    },
    async updateScheme() {
      this.show = true
      if (!await this.updateVerifyForm()) {
        this.show = false
        return
      }
      this.$swal({
        title: 'Desea continuar?',
        text: "Seguro que quiers editar el esquema",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {

          this.scheme_update.patient_id = this.data_patient.id
          this.scheme_update.appointee_id = this.getUser.id
          const send = {
            diagnosis: this.scheme_update.diagnosis,
            comments: this.scheme_update.comments,
            gender: this.scheme_update.gender,
            age: this.scheme_update.age,
            weight: this.scheme_update.weight,
            size: this.scheme_update.size,
            body_surface: this.scheme_update.body_surface,
            cycle: this.scheme_update.cycle,
            total_cycle: this.scheme_update.total_cycle,
            date_initial: this.scheme_update.date_initial,
            date_end: this.scheme_update.date_end,
            chemotherapy_treatment_reason_id: this.scheme_update.chemotherapy_treatment_reason_id,
            chemotherapy_id: this.scheme_update.chemotherapy_id,
            lines: null
          }
          let linesArray = []
          for (const a of this.new_lines) {
            if (a.new_line) {
              linesArray.push({
                order: a.order,
                premedication: a.premedication,
                total_dose: a.total_dose,
                filtration_rate: a.filtration_rate,
                dose_carboplatino: a.dose_carboplatino,
                product_id: a.product_id,
                dose: a.dose,
                type_solution: a.type_solution,
                chemotherapy_unit_dose_id: a.chemotherapy_unit_dose_id,
                chemotherapy_via_administration_id: a.chemotherapy_via_administration_id,
                distribution_volume: a.distribution_volume,
                infusion_time: a.infusion_time,
                chemotherapy_unit_time_id: a.chemotherapy_unit_time_id,
                treatment_days: a.treatment_days,
                creatinine: a.creatinine,
                auc: a.auc,
                chemotherapy_assign_id: this.scheme_update.id,
              })
            }
          }
          send.lines = linesArray
          for (const g of linesArray) {
            if (!g.product_id) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'producto requerido',
                  icon: 'warning',
                  variant: 'warning',
                },
              });
              this.show = false
              return false
            }
          }
          const response = await this.updateSchemeServer({data: send, id: this.scheme_update.id});
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Editado!',
              text: 'Su esquema se edito correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.show = false
            this.scheme_active = false
            this.scheme_active_update = false
            await this.getAllIdSchemes();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al crear esquema',
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },
    async updateVerifyForm() {
      if (!this.scheme_update.diagnosis) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Diagnostico',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.weight) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo peso requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.size) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo altura requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.body_surface) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Superficie corporal requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.cycle) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Ciclo requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.age) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Años requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.gender) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo genero requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.total_cycle) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Total de Ciclos requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.date_initial) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de inicio requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.date_end) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de final requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.chemotherapy_treatment_reason_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo razon de tratamiento requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme_update.chemotherapy_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo esquema requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      }

      return true
    },
    async verifyForm() {
      if (!this.scheme.diagnosis) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Diagnostico',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.weight) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo peso requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.age) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Años requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.gender) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo genero requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.size) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo altura requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.body_surface) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Superficie corporal requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.cycle) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Ciclo requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.total_cycle) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Total de Ciclos requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.date_initial) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de inicio requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.date_end) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Fecha de final requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.chemotherapy_treatment_reason_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo razon de tratamiento requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      } else if (!this.scheme.chemotherapy_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo esquema requerido',
            icon: 'warning',
            variant: 'warning',
          },
        });
        return false
      }
      return true
    },
    async createScheme() {
      this.show = true
      if (!await this.verifyForm()) {
        this.show = false
        return
      }
      this.$swal({
        title: 'Desea continuar?',
        text: "Esta seguro de guardar el esquema",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {


          this.scheme.patient_id = this.data_patient.id
          this.scheme.appointee_id = this.getUser.id
          const send = {
            appointee_id: this.scheme.appointee_id,
            diagnosis: this.scheme.diagnosis,
            comments: this.scheme.comments,
            weight: this.scheme.weight,
            size: this.scheme.size,
            body_surface: this.scheme.body_surface,
            cycle: this.scheme.cycle,
            total_cycle: this.scheme.total_cycle,
            date_initial: this.scheme.date_initial,
            date_end: this.scheme.date_end,
            chemotherapy_treatment_reason_id: this.scheme.chemotherapy_treatment_reason_id,
            patient_id: this.scheme.patient_id,
            age: this.scheme.age,
            gender: this.scheme.gender,
            chemotherapy_id: this.scheme.chemotherapy_id,
            lines: null
          }
          let linesArray = []
          for (const a of this.scheme.chemotherapy_lines) {
            if (a.new_line) {
              linesArray.push({
                order: a.order,
                premedication: a.premedication,
                total_dose: a.total_dose,
                filtration_rate: a.filtration_rate,
                dose_carboplatino: a.dose_carboplatino,
                product_id: a.product_id,
                dose: a.dose,
                chemotherapy_unit_dose_id: a.chemotherapy_unit_dose_id,
                chemotherapy_via_administration_id: a.chemotherapy_via_administration_id,
                distribution_volume: a.distribution_volume,
                infusion_time: a.infusion_time,
                chemotherapy_unit_time_id: a.chemotherapy_unit_time_id,
                treatment_days: a.treatment_days,
                creatinine: a.creatinine,
                type_solution: a.type_solution,
                auc: a.auc,
                chemotherapy_assign_id: a.chemotherapy_assign_id,
              })
            }
          }
          for (const g of linesArray) {
            if (!g.product_id) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'producto requerido',
                  icon: 'warning',
                  variant: 'warning',
                },
              });
              this.show = false
              return false
            }
          }
          send.lines = linesArray
          const response = await this.createSchemeServer(send);
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Guardando!',
              text: 'Su esquema se guardo correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.show = false
            this.scheme_active = false
            this.scheme_active_update = false
            await this.getAllIdSchemes();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al crear esquema',
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Cancelo el esquema ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })

    },
    async findProducts() {
      this.product = []
      const response = await this.findProduct();
      for (const a of response) {
        const de = a.default_code ? '(' + a.default_code + ')' : ''
        this.product.push({
          id: a.id,
          value: a.name + ' ' + de
        })
      }
    },
    async listViaAdmin() {
      this.vias = []
      const response = await this.getVias();
      for (const a of response) {
        this.vias.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async listDose() {
      this.dose = []
      const response = await this.getDose();
      for (const a of response) {
        this.dose.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async listTimes() {
      this.times = []
      const response = await this.getTimes();
      for (const a of response) {
        this.times.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async viewScheme() {
      this.select_reason =
          {id: '', value: ''}
      this.select_scheme = {id: '', value: ''},
          this.scheme_active = true
      this.scheme_active_update = false
    },

    async listReasons() {
      this.reasons = []
      const response = await this.getReason();
      for (const a of response) {
        this.reasons.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    addRowMedicines(idx, type) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines[idx].new_line = true

        if (!this.scheme.chemotherapy_lines[idx].product_id) {
          this.scheme.chemotherapy_lines[idx].product_id = this.scheme.chemotherapy_lines[idx].select_product.id
          this.scheme.chemotherapy_lines[idx].chemotherapy_via_administration_id = this.scheme.chemotherapy_lines[idx].select_vias.id
          this.scheme.chemotherapy_lines[idx].chemotherapy_unit_time_id = this.scheme.chemotherapy_lines[idx].select_time.id
          this.scheme.chemotherapy_lines[idx].chemotherapy_unit_dose_id = this.scheme.chemotherapy_lines[idx].select_dose.id
        }
        this.scheme.chemotherapy_lines.push({
          order: null,
          premedication: false,
          product_id: null,
          total_dose: null,
          dose: null,
          chemotherapy_unit_dose_id: null,
          chemotherapy_via_administration_id: null,
          distribution_volume: null,
          infusion_time: null,
          chemotherapy_unit_time_id: null,
          treatment_days: null,
          creatinine: null,
          type_solution: null,
          multiply: null,
          select_dose: {id: '', value: ''},
          select_solution: {id: '', value: ''},
          select_product: {id: '', value: ''},
          select_time: {id: '', value: ''},
          select_vias: {id: '', value: ''},
          auc: null,
          chemotherapy_assign_id: null,
          new_line: false
        })
      } else {
        this.new_lines[idx].new_line = true
        this.new_lines[idx].product_id = this.new_lines[idx].select_product.id
        this.new_lines[idx].chemotherapy_via_administration_id = this.new_lines[idx].select_vias.id
        this.new_lines[idx].chemotherapy_unit_time_id = this.new_lines[idx].select_time.id
        this.new_lines[idx].chemotherapy_unit_dose_id = this.new_lines[idx].select_dose.id
        this.new_lines.push({
          order: null,
          premedication: false,
          product_id: null,
          dose: null,
          total_dose: null,
          chemotherapy_unit_dose_id: null,
          chemotherapy_via_administration_id: null,
          distribution_volume: null,
          infusion_time: null,
          chemotherapy_unit_time_id: null,
          treatment_days: null,
          creatinine: null,
          multiply: null,
          type_solution: null,
          select_dose: {id: '', value: ''},
          select_solution: {id: '', value: ''},
          select_product: {id: '', value: ''},
          select_time: {id: '', value: ''},
          select_vias: {id: '', value: ''},
          auc: null,
          chemotherapy_assign_id: null,
          new_line: false
        })
      }
    },
    removeLineMedicines(id, type) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines.splice(id, 1)
      } else {
        this.new_lines.splice(id, 1)
      }
    },

    async selectScheme(type) {
      if (type === 'create') {
        this.scheme.chemotherapy_id = this.select_scheme.id
        this.select_table = false
        const response = await this.getSchemesOne(this.select_scheme.id);
        this.scheme.chemotherapy_lines = []
        for (const a of response.ChemotherapyLines) {
          this.scheme.chemotherapy_lines.push({
            order: a.order,
            premedication: a.premedication,
            product_id: a.product_id,
            dose: a.dose,
            chemotherapy_unit_dose_id: a.chemotherapy_unit_dose_id,
            select_dose: {
              id: a.chemotherapy_unit_dose_id,
              value: a.ChemotherapyUnitDose.name
            },
            select_time: {
              id: a.chemotherapy_unit_time_id,
              value: a.ChemotherapyUnitTime.name
            },
            select_vias: {
              id: a.chemotherapy_via_administration_id,
              value: a.ChemotherapyViaAdmin.name
            },
            select_product: {
              id: a.product_id,
              value: a.ProductTemplate.name
            },
            select_solution: {
              id: a.type_solution,
              value: a.type_solution
            },
            type_solution: a.type_solution,
            chemotherapy_via_administration_id: a.chemotherapy_via_administration_id,
            distribution_volume: a.distribution_volume,
            infusion_time: a.infusion_time,
            chemotherapy_unit_time_id: a.chemotherapy_unit_time_id,
            treatment_days: a.treatment_days,
            creatinine: a.creatinine,
            multiply: a.multiply,
            auc: a.auc,
            chemotherapy_assign_id: a.chemotherapy_assign_id,
            new_line: true
          })
          this.select_table = true
        }
        this.scheme.chemotherapy_lines.push({
          order: null,
          premedication: false,
          product_id: null,
          dose: null,
          chemotherapy_unit_dose_id: null,
          chemotherapy_via_administration_id: null,
          distribution_volume: null,
          infusion_time: null,
          chemotherapy_unit_time_id: null,
          treatment_days: null,
          creatinine: null,
          type_solution: null,
          select_dose: {id: '', value: ''},
          select_solution: {id: '', value: ''},
          select_product: {id: '', value: ''},
          select_time: {id: '', value: ''},
          select_vias: {id: '', value: ''},
          multiply: null,
          auc: null,
          chemotherapy_assign_id: null,
          new_line: false
        })
      } else {
        this.scheme_update.chemotherapy_id = this.select_scheme.id
        this.select_table_update = false
      }


    },
    async selectReason(type) {
      if (type === 'create') {
        this.scheme.chemotherapy_treatment_reason_id = this.select_reason.id
      } else {
        this.scheme_update.chemotherapy_treatment_reason_id = this.select_reason.id
      }


    },
    async selectProduct(type, idx) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines[idx].product_id = this.scheme.chemotherapy_lines[idx].select_product.id
      } else {
        this.new_lines[idx].product_id = this.new_lines[idx].select_product.id
      }
    },
    calculateTotalDose(type) {
      if (type === 'create') {
        for (const b of this.scheme.chemotherapy_lines) {
          if (b.product_id) {
            const total_dose = this.doseFor(b.select_dose.id, b.dose, 'create');
            b.total_dose = total_dose.toFixed(2)
          }
        }
      } else {
        for (const b of this.new_lines) {
          if (b.product_id) {
            const total_dose = this.doseFor(b.select_dose.id, b.dose, 'edit');
            b.total_dose = total_dose.toFixed(2)
          }
        }
      }
    },
    doseFor(id, dose, type) {
      let total = 0
      if (type === 'create') {
        for (const a of this.dose) {
          if (a.id === id) {
            if (a.value === "mg/kg") {
              return total = dose * this.scheme.weight
            } else if (a.value === "mg/m2") {
              return total = dose * this.scheme.body_surface
            } else {
              return total = 0
            }
          }
        }
      } else {
        for (const a of this.dose) {
          if (a.id === id) {
            if (a.value === "mg/kg") {
              return total = dose * this.scheme_update.weight
            } else if (a.value === "mg/m2") {
              return total = dose * this.scheme_update.body_surface
            } else {
              return total = 0
            }
          }
        }
      }


    },
    async selectDose(type, idx) {
      if (type === 'create') {
        let total = 0;
        for (const a of this.dose) {
          if (a.id === this.scheme.chemotherapy_lines[idx].select_dose.id) {
            if (a.value === "mg/kg") {
              const p = this.scheme.chemotherapy_lines[idx].dose * this.scheme.weight
              total = p.toFixed()
            } else if (a.value === "mg/m2") {
              const p = this.scheme.chemotherapy_lines[idx].dose * this.scheme.body_surface
              total = p.toFixed()
            } else {
              total = 0
            }
          }
        }
        this.scheme.chemotherapy_lines[idx].total_dose = total
        this.scheme.chemotherapy_lines[idx].chemotherapy_unit_dose_id = this.scheme.chemotherapy_lines[idx].select_dose.id
      } else {
        let total = 0;
        for (const a of this.dose) {
          if (a.id === this.new_lines[idx].select_dose.id) {
            if (a.value === "mg/kg") {
              const p = this.new_lines[idx].dose * this.scheme_update.weight
              total = p.toFixed()
            } else if (a.value === "mg/m2") {
              const p = this.new_lines[idx].dose * this.scheme_update.body_surface
              total = p.toFixed()
            } else {
              total = 0
            }
          }
        }
        this.new_lines[idx].total_dose = total
        this.new_lines[idx].chemotherapy_unit_dose_id = this.new_lines[idx].select_dose.id
      }
    },
    async selectTime(type, idx) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines[idx].chemotherapy_unit_time_id = this.scheme.chemotherapy_lines[idx].select_time.id
      } else {
        this.new_lines[idx].chemotherapy_unit_time_id = this.new_lines[idx].select_time.id
      }
    },
    async selectSolution(type, idx) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines[idx].type_solution = this.scheme.chemotherapy_lines[idx].select_solution ? this.scheme.chemotherapy_lines[idx].select_solution.id : ''
      } else {
        this.new_lines[idx].type_solution = this.new_lines[idx].select_solution ? this.new_lines[idx].select_solution.id : ''
      }
    },
    async selectVias(type, idx) {
      if (type === 'create') {
        this.scheme.chemotherapy_lines[idx].chemotherapy_via_administration_id = this.scheme.chemotherapy_lines[idx].select_vias.id
      } else {
        this.new_lines[idx].chemotherapy_via_administration_id = this.new_lines[idx].select_vias.id
      }
    },
    async exportPDFScheme() {
      const options = {
        orientation: "l",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(12);
      doc.text(`Paciente: ${this.data_patient.name}`, 2, 2);
      doc.text(`Medico: ${this.medical_name}`, 2, 3);
      doc.text(`Edad: ${this.scheme_update.age}.`, 2, 4);
      doc.text(`Peso: ${this.scheme_update.weight} Kg.`, 4.5, 4);
      doc.text(`Estatura: ${this.scheme_update.size} Mts.`, 7.5, 4);
      doc.text(`Superficie Corporal: ${this.scheme_update.body_surface}`, 11.5, 4);
      doc.text(`Sexo: ${this.scheme_update.gender === 'M' ? 'Masculino' : 'Femenino'}`, 16.5, 4);
      doc.text(`Dia de inicio: ${this.scheme_update.date_initial}`, 2, 5);
      doc.text(`Dia Final: ${this.scheme_update.date_end}`, 7, 5);
      doc.text(`Periocidad: ${this.scheme_update.cycle}`, 12, 5);
      doc.text(`Total de ciclos: ${this.scheme_update.total_cycle}`, 15, 5);
      doc.text(`Diagnostico: ${this.scheme_update.diagnosis}`, 2, 6);
      doc.text(`Tratamiento: ${this.select_scheme.value}`, 2, 7);
      doc.text(`Comentarios: ${this.scheme_update.comments ? this.scheme_update.comments : ''}`, 2, 8);
      let lines = []
      doc.setFontSize(8);
      for (const f of this.new_lines) {
        if (f.select_product.value) {
          lines.push({
            order: f.order,
            type_solution: f.type_solution,
            distribution_volume: f.distribution_volume,
            dose: f.dose,
            infusion_time: f.infusion_time,
            treatment_days: f.treatment_days,
            premedication: f.premedication ? 'si' : 'no',
            product: f.select_product.value,
            unit_dose: f.select_dose.value,
            via_admin: f.select_vias.value,
            unit_time: f.select_time.value,
          })
        }
      }

      doc.autoTable({
        columns: [
          {header: 'Orden', dataKey: 'order'},
          {header: 'Preme...', dataKey: 'premedication'},
          {header: 'Medi...', dataKey: 'product'},
          {header: 'Dosi...', dataKey: 'dose'},
          {header: 'U. Dos...', dataKey: 'unit_dose'},
          {header: 'Via. Admin...', dataKey: 'via_admin'},
          {header: 'VD', dataKey: 'distribution_volume'},
          {header: 'Solucion', dataKey: 'type_solution'},
          {header: 'Tiem. infu...', dataKey: 'infusion_time'},
          {header: 'Unidad. I...', dataKey: 'unit_time'},
          {header: 'Dias Tx...', dataKey: 'treatment_days'},


        ],
        startY: 9,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: lines,
      })
      doc.save(`${this.data_patient.name}-${this.select_scheme.value}.pdf`);
    },
    async deleteScheme(id) {

      this.show = true
      this.$swal({
        title: 'Desea continuar?',
        text: "Esta seguro de borrar el esquema",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.deleteSchemeStore(id)
          if (response) {
            this.$swal({
              icon: 'success',
              title: 'Guardando!',
              text: 'Su esquema se borro correctamente',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.show = false
            await this.getAllIdSchemes();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al borrar el esquema',
                icon: 'error',
                variant: 'warning',
              },
            });
            this.show = false
          }

        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Cancelo el borrado del esquema ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  }

}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

/* Zebra striping */
tr:nth-of-type(odd) {
  background: #eee;
}

th {
  background: #333;
  color: white;
  font-weight: bold;
}

td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: center;

}

thead tr th {
  font-size: 11px;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1000px) {

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
  Label the data
  */
  td:nth-of-type(1):before {
    content: "Orden";
  }

  td:nth-of-type(2):before {
    content: "Premedicac....";
  }

  td:nth-of-type(3):before {
    content: "Medicamento";
  }

  td:nth-of-type(4):before {
    content: "Dosis";
  }

  td:nth-of-type(5):before {
    content: "U. Dosis";
  }

  td:nth-of-type(6):before {
    content: "Dosis total";
  }
  td:nth-of-type(7):before {
    content: "U. Dosis";
  }
  td:nth-of-type(8):before {
    content: "Via. Admin";
  }

  td:nth-of-type(9):before {
    content: "VD";
  }
  td:nth-of-type(10):before {
    content: "Solución";
  }

  td:nth-of-type(11):before {
    content: "Tiempo de Inf.";
  }

  td:nth-of-type(12):before {
    content: "Unidad I";
  }

  td:nth-of-type(13):before {
    content: "Dias Tx";
  }


  td:nth-of-type(14):before {
    content: "Creatinina";
  }

  td:nth-of-type(15):before {
    content: "Auc";
  }

  td:nth-of-type(16):before {
    content: "Tasa filtración";
  }

  td:nth-of-type(17):before {
    content: "Dosis de Carboplatino";
  }


  td:nth-of-type(18):before {
    content: "Acciones";
  }


}

.width-table-input {
  color: black;
  width: 90px;
}

.font-black-result {
  color: black;
}
</style>
