<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-modal
        hide-footer
        id="modal-email"
        ref="my-modal"
        :title="`Enviar Email`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Correo"
          >
            <b-form-input
                v-model="send.email"
                placeholder="Correo"
                type="email"
            />
            <span v-if="email_is" style="color: red">Correo requerido </span>
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="sendEmail"
              style="width: 100%"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Enviar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-modal
        hide-footer
        id="modal-phone"
        ref="my-modal"
        :title="`Enviar whatsapp`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Whatsapp"
          >
            <b-form-input
                v-model="send.phone"
                placeholder="Whatsapp"
                type="email"
            />
            <span v-if="phone_is" style="color: red">Celular requerido </span>
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="sendWhatsapp"
              style="width: 100%"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Enviar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-modal
        hide-footer
        id="modal-copy"
        ref="my-modal"
        :title="`Copiar Links`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        size="xl"
    >
      <b-row v-for="(a, idx) in lines_copy" :key="idx">
        <b-col  cols="6">
          <p>{{a.title}}</p>
          <b-form-input v-model="a.url" disabled="">
          </b-form-input>
        </b-col>
        <b-col  cols="6">
          <br><br>
          <b-button
              @click="copyLink(a.url)"
              style="width: 25%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Copiar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col cols="12">
        <h3 style="text-align: center">Compartir</h3>
      </b-col>
      <b-col cols="12">
        <b-row class="mt-5">
          <b-col cols="4">
            <h3 style="text-align: center">Recetas</h3>
            <b-list-group>
              <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in recipes" :key="idx"
              >
                <b-form-checkbox
                    v-model="a.status"
                    @input="checkEmail(idx, a.id, 'recipe', a.status)"
                >
                </b-form-checkbox>
                {{ resHoursOdooFormat(a.createdAt) }}(ver
                archivo)
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Esquemas</h3>
            <b-list-group>
              <b-list-group-item v-for="(b, idx2) in list_schemes" :key="idx2" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="b.status"
                    @input="checkEmail(idx2, b.id, 'schemes', b.status)"
                >
                </b-form-checkbox>
                {{ b.name }} (ver
                archivo {{ resHoursOdooFormat(b.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Tratamientos</h3>
            <b-list-group>
              <b-list-group-item v-for="(c, idx3) in treatments" :key="idx3" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="c.status"
                    @input="checkEmail(idx3, c.id, 'treatment', c.status)"
                >
                </b-form-checkbox>
                {{ c.name }} (ver
                archivo {{ resHoursOdooFormat(c.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModal"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Por correo
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModalPhone"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Por Whatsapp
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModalCopy"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Urls
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem, BModal, BOverlay,
  BRow, VBModal, BFormCheckbox,
} from "bootstrap-vue";
import slugify from 'slugify'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from 'moment';

export default {
  name: "PatientShare",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  data() {
    return {
      patient_id: null,
      show: false,
      email_is: false,
      phone_is: false,
      number_picking: null,
      recipes: [],
      list_schemes: [],
      treatments: [],
      check_recipe: [],
      lines_copy: [],
      pricelist_patient: null,
      send: {
        bucket: null,
        name: null,
        id: null,
        email: null,
        phone: null,
        array_send: []
      }
    }
  },
  mounted() {
    this.$root.$on('chargeShare', async () => {
      // your code goes here
      this.patient_id = this.data_patient.id
      await this.createShareBucket()
      await this.getRecipes()
      await this.getAllIdSchemes()
      await this.getAllTreatment()
      await this.sendParams()
    })
  },
  methods: {
    ...mapActions('share', ['createName', 'shareEmail', 'shareWhatsapp','shareCopy']),
    ...mapActions('treatment', ['findTreatmentAppointment', 'getOneTreatmentNoFiles', 'getStockPicking','findTreatmentAppointmentCustomer']),
    ...mapActions('patient', ['findPatientAllRecipe', 'findPatientRecipe', 'findPricelistPatient']),
    ...mapActions('schemes', ['getSchemesPatient', 'getSchemesPatientOne']),
    async copyLink(url){

      await navigator.clipboard.writeText(url);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Se copio el link correctamente',
          icon: 'EditIcon',
          variant: 'success',
        },
      });

    },
    openModal() {
      this.$bvModal.show('modal-email')
    },
    openModalPhone() {
      this.$bvModal.show('modal-phone')
    },
    async sendWhatsapp() {
      this.show = true
      this.phone_is = false
      if (this.send.phone) {
        this.phone_is = false
        const response = await this.shareWhatsapp(this.send);
        if (response.status) {
          window.open(`https://api.whatsapp.com/send?phone=521${this.send.phone}&text=Hola buen dia anexo los archivos %0A${response.lines}`)
          this.$bvModal.hide('modal-phone')
          this.send.array_send = []
          this.recipes = []
          this.list_schemes = []
          this.treatments = []
          this.show = false
          await this.getRecipes()
          await this.getAllIdSchemes()
          await this.getAllTreatment()
        } else {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al enviar el whatsapp consulte con su administrador',
              icon: 'warning',
              variant: 'warning',
            },
          });
        }
      } else {
        this.show = false
        this.phone_is = true
      }
    },
    async openModalCopy() {
      this.show = true
      const response = await this.shareCopy(this.send);
      if (response.status) {
        this.lines_copy = response.lines
        this.show = false
        this.$bvModal.show('modal-copy')
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al generar url consulte con su administrador',
            icon: 'warning',
            variant: 'warning',
          },
        });
      }
    },
    async sendEmail() {
      this.show = true
      this.email_is = false
      if (this.send.email) {
        this.email_is = false
        const response = await this.shareEmail(this.send);
        if (response) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Correo se envio correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
          this.show = false
          this.send.array_send = []
          this.recipes = []
          this.list_schemes = []
          this.treatments = []
          await this.getRecipes()
          await this.getAllIdSchemes()
          await this.getAllTreatment()
          this.$bvModal.hide('modal-email')
        } else {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al enviar el correo consulte con su administrador',
              icon: 'warning',
              variant: 'warning',
            },
          });
        }
      } else {
        this.show = false
        this.email_is = true
      }
    },
    async checkEmail(idx, id, type, verify) {
      if (type === 'recipe') {
        if (verify) {
          const response = await this.findPatientRecipe(id)
          const a = await this.exportPDFRecipe(response)
          this.recipes[idx].status = verify
          const name_file = this.data_patient.name + this.resHoursOdooFormat(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })
        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else if (type === 'treatment') {
        if (verify) {
          const response = await this.getOneTreatmentNoFiles(id)
          const a = await this.exportPDFTreatment(response)
          this.treatments[idx].status = verify
          const name_file = this.data_patient.name +response.name + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })

        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else {
        if (verify) {
          const response = await this.getSchemesPatientOne({id, patient_id: this.patient_id});
          const a = await this.exportPDFScheme(response);
          this.list_schemes[idx].status = verify
          const name_file =  this.data_patient.name + response.Chemotherapy.name + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            name: slugify(name_file),
            type: type,
            value: type + id
          })
        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      }
    },
    async sendParams() {
      this.send.id = this.data_patient.id
      this.send.name = this.data_patient.name
      this.send.email = this.data_patient.email
      this.send.bucket = this.data_patient.bucket_path
      this.send.phone = this.data_patient.mobile
      const response_pricelist = await this.findPricelistPatient(this.data_patient.id)
      this.pricelist_patient = response_pricelist.name
    },
    async createShareBucket() {
      await this.createName(this.patient_id)
    },
    async getRecipes() {
      this.recipes = []
      const response = await this.findPatientAllRecipe(this.patient_id);
      for (const a of response) {
        this.recipes.push({
          id: a.id,
          createdAt: a.createdAt,
          status: false

        })
      }
    },
    async exportPDFRecipe(data) {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 2, 1);
      doc.text(`${data.ResPartnerAppointment.name}`, 2, 2);
      doc.text(`${this.dateStringEs(data.date_consultation)}`, 2, 3);


      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 4,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: data.ClinicRecipes,
      })
      return doc.output('datauristring')
    },
    async exportPDFTreatment(data) {
      const d1 = this.resHoursOdoo(data.app_dt_start)
      const d2 = this.resHoursOdoo(data.app_dt_stop)
      const date = d1.split(" ")
      const date2 = d2.split(" ")
      var doc = new jsPDF('p', 'pt', 'a4');
      const count_lines = data.ClinicTreatment.ClinicTreatmentLines.length
      const response_pi = await this.getStockPicking(data.id)
      let picking;
      if (response_pi) {
        picking = response_pi
      } else {
        picking = null
      }
      doc.text(`Tratamiento`, 40, 40);
      if (picking) {
        doc.text(`Salida: ${picking}`, 350, 40);
      } else {

      }
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.text(`Tipo de paciente: ${this.pricelist_patient}`, 40, 100);
      doc.text(`Fecha: ${date[0]}`, 40, 130);
      doc.text(`Horario: ${date[1]} - ${date2[1]}`, 200, 130);
      let lines = []
      for (const z of data.ClinicTreatment.ClinicTreatmentLines) {
        lines.push({
          name: z.ProductTemplate.name,
          qty: z.qty
        })
      }
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'name'},
          {header: 'Lote', dataKey: ''},
          {header: 'Caducidad', dataKey: 'a'},
          {header: 'Cantidad', dataKey: 'qty'},
        ],
        body: lines,
        margin: {top: 150},
      })
      let finalY = doc.autoTable.previous.finalY
      if (finalY >= 600) {
        doc.addPage();
        doc.text(`Extras:____________________________________________________`, 40, 60);
        doc.text(`__________________________________________________________`, 40, 100);
        doc.text(`__________________________________________________________`, 40, 140);
        doc.text(`Nombre:____________________`, 40, 220);
        doc.text(`Firma:______________________`, 40, 260);
        doc.text(`Nombre:____________________`, 305, 220);
        doc.text(`Firma:______________________`, 305, 260);
      } else {
        doc.text(`Extras:____________________________________________________`, 40, finalY + 40);
        doc.text(`__________________________________________________________`, 40, finalY + 80);
        doc.text(`__________________________________________________________`, 40, finalY + 120);
        doc.text(`Nombre:____________________`, 40, finalY + 220);
        doc.text(`Firma:______________________`, 40, finalY + 260);
        doc.text(`Nombre:____________________`, 305, finalY + 220);
        doc.text(`Firma:______________________`, 305, finalY + 260);
      }
      return doc.output('datauristring')
    },

    async exportPDFScheme(data) {

      const options = {
        orientation: "l",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(12);
      doc.text(`Paciente: ${this.data_patient.name}`, 2, 2);
      doc.text(`Edad: ${data.age}.`, 2, 4);
      doc.text(`Peso: ${data.weight} Kg.`, 4.5, 4);
      doc.text(`Estatura: ${data.size} Mts.`, 7.5, 4);
      doc.text(`Superficie Corporal: ${data.body_surface}`, 11.5, 4);
      doc.text(`Sexo: ${data.gender === 'M' ? 'Masculino' : 'Femenino'}`, 16.5, 4);
      doc.text(`Dia de inicio: ${data.date_initial}`, 2, 5);
      doc.text(`Dia Final: ${data.date_end}`, 7, 5);
      doc.text(`Periocidad: ${data.cycle}`, 12, 5);
      doc.text(`Total de ciclos: ${data.total_cycle}`, 15, 5);
      doc.text(`Diagnostico: ${data.diagnosis}`, 2, 6);
      doc.text(`Tratamiento: ${data.Chemotherapy.name}`, 2, 7);
      doc.text(`Comentario: ${data.comments}`, 2, 8);
      let lines = []
      doc.setFontSize(10);
      for (const f of data.ChemotherapyLinesAssigns) {
        if (f.ProductTemplate) {
          lines.push({
            order: f.order,
            total_dose: f.total_dose,
            auc: f.auc,
            creatinine: f.creatinine,
            distribution_volume: f.distribution_volume,
            dose: f.dose,
            dose_carboplatino: f.dose_carboplatino,
            filtration_rate: f.filtration_rate,
            infusion_time: f.infusion_time,
            treatment_days: f.treatment_days,
            premedication: f.premedication ? 'si' : 'no',
            product: f.ProductTemplate.name,
            unit_dose: f.ChemotherapyUnitDose.name,
            via_admin: f.ChemotherapyViaAdmin.name,
            unit_time: f.ChemotherapyUnitTime.name,
          })
        }
      }

      doc.autoTable({
        columns: [
          {header: 'Orden', dataKey: 'order'},
          {header: 'Preme...', dataKey: 'premedication'},

          {header: 'Medi...', dataKey: 'product'},
          {header: 'Dosi...', dataKey: 'dose'},
          {header: 'U. Dos...', dataKey: 'unit_dose'},
          {header: 'Total', dataKey: 'total_dose'},
          {header: 'Via. Admin...', dataKey: 'via_admin'},
          {header: 'VD', dataKey: 'distribution_volume'},
          {header: 'Tiem. infu...', dataKey: 'infusion_time'},
          {header: 'Unidad. I...', dataKey: 'unit_time'},
          {header: 'Dias Tx...', dataKey: 'treatment_days'},
          {header: 'Creatinina...', dataKey: 'creatinine'},
          {header: 'Auc', dataKey: 'auc'},
          {header: 'Tasa Fil...', dataKey: 'filtration_rate'},

          {header: 'Dose Carbo...', dataKey: 'dose_carboplatino'},

        ],
        startY: 9,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: lines,
      })
      return doc.output('datauristring')
    },
    async getAllIdSchemes() {
      this.list_schemes = []
      const response = await this.getSchemesPatient(this.data_patient.id);
      for (const a of response) {
        this.list_schemes.push({
          id: a.id,
          name: a.Chemotherapy.name,
          createdAt: a.createdAt,
          status: false
        })
      }
    },
    async getAllTreatment() {
      this.treatments = []
      const response = await this.findTreatmentAppointmentCustomer(this.data_patient.id);
      for (const a of response) {
        this.treatments.push({
          id: a.id,
          name: a.AppointmentTreat.name,
          createdAt: a.createdAt,
          status: false
        })
      }
    },
  }
}
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

</style>
