<template>
  <b-card
      footer-class="text-muted"
  >
    <b-row class="mb-1">
      <b-col style="margin-bottom: 40px"
          lg="12"
      >
        <h3 style="text-align: center">Paciente: {{ user.name }}</h3>
        <h4 v-if="select_medical" style="text-align: center; margin-top: 20px">Medico: {{ select_medical.value }}</h4>
      </b-col>
      <b-col
          lg="6"
      >

        <h4 style="text-align: center" v-if="user.birthday">Fecha de nacimiento: {{ dateStringMx(user.birthday) }}</h4>
        <h4 style="text-align: center" v-if="user.birthday">Edad: {{ yearsCalculate(user.birthday) }} Años</h4>
      </b-col>
      <b-col
          lg="6"
      >
        <h4 style="text-align: center">Numero expediente: {{ user.patient_file_number }}</h4>
        <h4 style="text-align: center">Celular: {{ user.mobile }}</h4>

      </b-col>
    </b-row>
    <b-form @submit.prevent="updateUser(user)">
      <b-row>
        <b-col
            cols="12" lg="12"
        >
          <app-collapse >
            <app-collapse-item title="Información General">
              <b-row>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Nombre"
                      label-for="Nombre"
                  >
                    <b-form-input
                        v-model="user.name"
                        id="basicInput"
                        placeholder="Paciente"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group

                      label="Numero expediente"
                      label-for="Numero expediente"
                  >
                    <b-form-input
                        v-model="user.patient_file_number"
                        readonly="readonly"
                        placeholder="Numbero expediente"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group>
                    <h5>Fecha de nacimiento</h5>
                    <flat-pickr
                        v-model="user.birthday"
                        class="form-control"
                        :config="{ enableTime: false,dateFormat: 'Y-m-d',  locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Telefono"
                      label-for="Telefono"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.phone"
                        placeholder="Telefono"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="4"
                >
                  <b-form-group
                      label="Celular"
                      label-for="Celular"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.mobile"
                        placeholder="Celular"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="4"
                >
                  <b-form-group
                      label="Correo"
                      label-for="Correo"
                  >
                    <b-form-input
                        v-model="user.email"
                        type="email"
                        placeholder="Correo"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Doctores"
                      label-for="h-email"
                  >
                    <v-select
                        @input="changeMedical"
                        v-model="select_medical"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="medicals"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Domicilio">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="País"
                      label-for="h-email"
                  >
                    <b-form-input
                        v-if="!active_select_country"
                        v-model="name_country"
                        @click="active_select_country = true"
                        placeholder="País"
                    />
                    <vue-autosuggest
                        v-else
                        :suggestions="countries"
                        :input-props="{id:'',class:'form-control', placeholder:'Seleccionar país'}"
                        @input="getCountrySelect"
                        @selected="countryVerify"

                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>


                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Estado"
                      label-for="h-email"
                  >
                    <v-select
                        @input="selectState()"
                        v-model="select_state"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="states"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Municipios"
                      label-for="h-email"
                  >
                    <v-select
                        v-model="select_cities"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="cities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group

                      label="Colonia"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.l10n_mx_edi_colony"
                        id="colony"
                        placeholder="Colonia"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Codigo postal"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.zip"
                        @keypress="isNumber($event)"
                        id="zip"
                        placeholder="Codigo postal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Calle"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_name"
                        id="name"
                        placeholder="Calle"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                  <b-form-group
                      label="Numero Casa"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_number"
                        id="num"
                        placeholder="Numero Casa"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Información de facturación">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Tipo de Paciente"
                      label-for="h-email"
                  >
                    <v-select
                        @input="pricelistVerify()"
                        v-model="select_price_list"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="pricelists"
                    />
                  </b-form-group>

                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group

                      label="Rfc"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.vat"
                        id="rfc"
                        placeholder="rfc"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Regimen Fiscal" label-for="h-email">
                    <v-select
                        v-model="regimen_select"
                        @input="changeRegimen()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="regimens"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-col>
        <b-col cols="12" style="margin-top: 30px">
          <b-button
              style="width: 100%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>

        </b-col>
      </b-row>

    </b-form>

    <b-tabs style="margin-top: 30px" v-if="user.id">
      <b-tab active title="Información / H.C.">
        <InformationPatient title="Información / H.C." :data_patient="user"/>
      </b-tab>
      <b-tab title="Archivos">
        <FilesPatient title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Consultas" v-if="user" @click="executeConsultation">
        <ConsultationsPatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Recetas" v-if="user" @click="executeRecipe">
        <RecipePatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Esquemas" v-if="user" @click="executeSchema">
        <PatientChemotherapy v-if="user" title="Esquemas" :data_patient="user"/>
      </b-tab>
      <b-tab title="Compartir" v-if="user" @click="executeToShare">
        <PatientShare v-if="user" title="Compartir" :data_patient="user"/>
      </b-tab>
      <b-tab title="Cuestionario" v-if="user" @click="executeToQuestion">
        <PatientQuestionnaire v-if="user" title="Cuestionario" :data_patient="user"/>
      </b-tab>
      <b-tab title="Biocomposiciones" v-if="user" @click="executeToBio">
        <FilesBiocompositions v-if="user" title="Biocomposiciones" :data_patient="user"/>
      </b-tab>

      <b-tab title="Estudios de Laboratorio" v-if="user" @click="executeToLaboratories">
        <FilesLaboratories v-if="user" title="Estudios de Laboratorio" :data_patient="user"/>
      </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>

import {
  BTabs, BTab, BCard, BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton, BForm
} from 'bootstrap-vue'
import {mapGetters, mapActions} from "vuex";
import PatientForms from "@/components/Patients/PatientForms";
import HistoricClinic from "@/components/Patients/HistoricClinic";
import InformationPatient from "@/components/Patients/InformationPatient";
import PatientChemotherapy from "@/components/Patients/PatientChemotherapy";
import FilesPatient from "@/components/Patients/FilesPatient";
import RecipePatient from "@/components/Patients/RecipePatient";
import ConsultationsPatient from "@/components/Patients/ConsultationsPatient";
import PatientShare from "@/components/Patients/PatientShare";
import {VueAutosuggest} from 'vue-autosuggest'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FilesBiocompositions from "@/components/Patients/FilesBiocompositions";
import FilesLaboratories from "@/components/Patients/FilesLaboratories";
import PatientQuestionnaire from "@/components/Patients/PatientQuestionnaire";

export default {
  name: "DetailPatient",
  components: {
    BForm,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BButton,
    PatientForms,
    vSelect,
    VueAutosuggest,
    flatPickr,
    HistoricClinic,
    InformationPatient,
    FilesPatient,
    ConsultationsPatient, RecipePatient, PatientChemotherapy, PatientShare,
    AppCollapse,
    AppCollapseItem,
    FilesBiocompositions,
    FilesLaboratories,
    PatientQuestionnaire
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabs: [],
      regimen_select: {id: "", value: "Seleccione un regimen"},
      regimens: [
        {id: "601", value: "General de Ley Personas Morales"},
        {id: "603", value: "Personas Morales con Fines no Lucrativos"},
        {id: "605", value: "Sueldos y Salarios e Ingresos Asimilados a Salarios"},
        {id: "606", value: "Arrendamiento"},
        {id: "608", value: "Demás ingresos"},
        {id: "609", value: "Consolidación"},
        {id: "610", value: "Residentes en el Extranjero sin Establecimiento Permanente en México"},
        {id: "611", value: "Ingresos por Dividendos (socios y accionistas)"},
        {id: "612", value: "Personas Físicas con Actividades Empresariales y Profesionales"},
        {id: "614", value: "Ingresos por intereses"},
        {id: "616", value: "Sin obligaciones fiscales"},
        {id: "620", value: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"},
        {id: "621", value: "Incorporación Fiscal"},
        {id: "622", value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"},
        {id: "623", value: "Opcional para Grupos de Sociedades"},
        {id: "624", value: "Coordinados"},
        {id: "628", value: "Hidrocarburos"},
        {id: "607", value: "Régimen de Enajenación o Adquisición de Bienes"},
        {id: "629", value: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"},
        {id: "630", value: "Enajenación de acciones en bolsa de valores"},
        {id: "615", value: "Régimen de los ingresos por obtención de premios"},
        {
          id: "625",
          value: "Régimen de las actividades empresariales con ingresos a traves de plataformas inalambricas"
        },
        {id: "626", value: "Régimen Simplificado de confianza"},
      ],
      select_state: {id: '', value: ''},
      select_cities: {id: '', value: ''},
      select_medical: {id: '', value: 'Seleccione un medico'},
      countries: [],
      name_country: '',
      active_select_country: false,
      states: [],
      cities: [],
      pricelists: [],
      medicals: [],
      idactive: null,
      select_group: {id: '', value: ''},
      groups: [],
      patient_id: null,
      select_price_list: {id: '', value: ''},
      get_country: '',
      user: {
        id: '',
        name: '',
        patient_file_number: '',
        l10n_mx_edi_fiscal_regime: '',
        property_product_pricelist: '',
        phone: '',
        mobile: '',
        email: '',
        appointee_id: '',
        is_patient: true,
        street: '',
        street_number: '',
        city_id: '',
        state_id: '',
        country_id: '',
        zip: '',
        l10n_mx_edi_colony: '',
        l10n_mx_edi_locality: '',
        vat: ''
      }
    }
  },
  async created() {
    await this.getOne();
    await this.findDoctor()
    await this.tabsFind()
    await this.getPriceListSelect()

    await this.patientVerify(this.user.id)
  },

  methods: {
    ...mapActions('patient', ['createPatient', 'findConsecutiveId', 'findPatientOne', 'updatePatient', 'findPricelistPatient']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentGroups', 'findApointeeSearch', 'findAppointmentDoctorPlace', 'findAppointmentPriceList']),
    ...mapActions('clinic_patient', ['nameClinic']),
    ...mapActions('address', ['findCountry', 'findCountryState', 'findCountryCities']),
    async patientVerify(id) {
      const response = await this.findPricelistPatient(id);
      this.select_price_list.id = response.id
      this.select_price_list.value = response.name
      this.user.property_product_pricelist = response.id

    },
    async executeToShare() {
      await this.$root.$emit('chargeShare')
    },
    async executeToLaboratories() {
      await this.$root.$emit('chargeLaboratories')
    },
    async executeToBio() {
      await this.$root.$emit('chargeBio')
    },
    async executeToQuestion() {
      await this.$root.$emit('chargePatientQuestion')
    },
    async executeSchema() {
      await this.$root.$emit('chargeSchema')
    },
    async executeConsultation() {
      await this.$root.$emit('chargeConsultation')
    },
    async executeRecipe() {
      await this.$root.$emit('chargeRecipe')
    },
    changeRegimen() {
      this.user.l10n_mx_edi_fiscal_regime = this.regimen_select.id;
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async tabsFind() {
      this.tabs = await this.nameClinic()
    },
    async pricelistVerify() {
      this.user.property_product_pricelist = this.select_price_list.id;
    },

    async groupsVerify() {
      await this.searchGroup(this.select_group.id)
    },
    activeTab(id) {

      this.idactive = id

    },
    async getOne() {
      let regimen = null
      const id = this.$route.params.id
      this.patient_id = parseInt(id);
      const response = await this.findPatientOne(id);
      if (response.l10n_mx_edi_fiscal_regime) {
        for (const a of this.regimens) {
          if (a.id === response.l10n_mx_edi_fiscal_regime) {
            regimen = a.value
          }
        }
        this.regimen_select.id = response.l10n_mx_edi_fiscal_regime
        this.regimen_select.value = regimen
      } else {
        this.regimen_select.id = ''
        this.regimen_select.value = ''
      }
      this.user = response
      if (!this.user.patient_file_number) {
        this.user.patient_file_number = await this.findConsecutiveId();
      }
      if (response.ResCountry) {
        await this.verifyCountry(response)
      }

      await this.verifyDoctor(response)
    },
    async verifyCountry(data) {
      this.name_country = data.ResCountry.name
      this.select_state.id = data.ResCountryState.id
      this.select_state.value = data.ResCountryState.name
      if (data.ResCity) {
        this.select_cities.value = data.ResCity.name
        this.select_cities.id = data.ResCity.id
      } else {
        this.select_cities.value = ''
        this.select_cities.id = ''
      }
      const item = {
        item: {
          id: data.ResCountry.id
        }
      }
      await this.countryVerify(item)
    },
    async verifyDoctor(data) {
      this.select_medical.id = data.ResPartnerAppointment.id
      this.select_medical.value = data.ResPartnerAppointment.name


    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        if (!a.is_place) {
          this.groups.push({
            id: a.id,
            value: a.group_name,
            verifed: a.is_place
          })
        }

      }
    },
    changeMedical() {
      this.user.appointee_id = this.select_medical.id;
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async findDoctor() {
      const response = await this.findAppointmentDoctorPlace()
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }
    },
    async verifiedForm() {

      if (this.user.name === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo nombre requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.phone) {
        if (this.user.phone.length !== 10) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Telefono necesita 10 caracteres",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
      }
      if (this.user.email !== "") {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(this.user.email) === false) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo email no es un correo",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }

      }
      if (!this.user.birthday) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo fecha de nacimiento requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.mobile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Celular requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.mobile.length !== 10) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo Celular necesita 10 caracteres",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }
      if (!this.user.country_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Pais requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.state_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo estado  requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.city_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo municipio requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.property_product_pricelist) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Tipo de Paciente requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }

      return true


    },
    async updateUser(user) {
      this.user.city_id = this.select_cities.id
      let response;
      delete this.user.ResCountryState
      delete this.user.ResCity
      delete this.user.ResCountry
      delete this.user.ResPartnerAppointment
      const re = await this.verifiedForm()
      if (!re) {
        return
      }
      response = await this.updatePatient(this.user)

      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario Editado',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.$router.push('/pacientes');

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al crear usuario',
            icon: 'error',
            variant: 'warning',
          },
        });

      }
    },
    async countryVerify(item) {
      this.user.country_id = item.item.id
      this.states = []
      const response = await this.findCountryState(this.user.country_id);
      for (const a of response) {
        this.states.push({
          id: a.id,
          value: a.name,
        })
      }
      await this.searchCities()
    },
    async searchCities() {
      this.cities = []
      let data = {}
      if (this.user.state_id) {
        data = {
          country_id: this.user.country_id,
          state_id: this.user.state_id
        }
      } else {
        data = {
          country_id: this.user.country_id,
          state_id: null
        }
      }
      const response = await this.findCountryCities(data);
      for (const a of response) {
        this.cities.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async selectState() {
      this.user.state_id = this.select_state.id;

      await this.searchCities()
    },
    async getCountrySelect(text) {
      if (text === '' || text === undefined) {
        this.countries = []
        return
      }
      this.countries = []
      let cou = []
      const response = await this.findCountry(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.countries = [{
        data: cou,
      }]

    }

  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
